<template>
  <div id="app" class="outer-container">
    <router-view />
    <span class="noSpan"></span>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/layout/index.vue";

@Component({
  components: {
    Layout,
  },
})
export default class App extends Vue {
  hasLayout = false;
  created(): void {
    this.hasLayout = !~window.location.href.indexOf("retrieve");
  }
}
</script>

<style scoped>
/* @font-face {
  font-family: "ABBvoice CNSG App Md";
  src: url("./assets/fonts/ABBvoice_ACNSG_Md.ttf");
} */
@font-face {
  font-family: "ABBvoice CNSG App Md";
  src: url("./assets/fonts/ABBvoice_WCNSG_Md.woff2");
}

/* @font-face {
  font-family: "ABBvoice CNSG App Rg";
  src: url("./assets/fonts/ABBvoice_ACNSG_Rg.ttf");
} */
@font-face {
  font-family: "ABBvoice CNSG App Rg";
  src: url("./assets/fonts/ABBvoice_WCNSG_Rg.woff2");
}

@font-face {
  font-family: "ABBvoice CNSG App Md Index";
  src: url("./assets/fonts/ABBvoice_ACNSG_Md_login.ttf");
}
.noSpan {
  font-family: "ABBvoice CNSG App Md";
}
</style>
