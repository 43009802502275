import { StoreOptions } from "vuex";
const common: StoreOptions<any> = {
  state: {
    pc_client_url: "",
  },

  getters: {
    getPcClinetUrl(state) {
      return state.pc_client_url;
    },
  },

  mutations: {
    SET_PC_CLIENT_URL(state, payload) {
      state.pc_client_url = payload;
    },
  },

  actions: {
    setPcClientUrl({ commit }, val) {
      commit("SET_PC_CLIENT_URL", val);
    },
  },
};

export default {
  namespaced: true,
  ...common,
};
