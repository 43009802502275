<template>
  <div class="msp-layout" ref="layout">
    <div class="msp-header">
      <div class="msp-header-content">
        <div class="logo">
          <img src="@/assets/abb-logo.svg" alt="logo" @click="BackToHome" />
          <div class="line"></div>
          <div class="text">
            <span>Maintenance and Service Platform</span>
            <div>
              <span>KNX数字运维管理平台</span><span v-if="version" class="versionSpan">{{ "V" + version }}</span
              ><span :class="isTD ? 'tdTypeSpan typeSpan' : 'boTypeSpan typeSpan'" v-if="curmenus !== 'role'">{{
                isTD ? "运维端" : "业主端"
              }}</span>
            </div>
          </div>
        </div>
        <div style="display: flex">
          <div
            class="menu-name"
            :class="curmenus === menu.name ? 'active' : ''"
            v-for="menu in menus"
            :key="menu.name"
            @click="toPage(menu.name)"
          >
            {{ menu.title }}
            <span
              v-if="(menu.name === 'TDmessage' || menu.name === 'BOmessage') && totalUnreadMsgCount"
              class="countTip"
            ></span>
          </div>
        </div>
        <div class="user">
          <!-- <img src="@/assets/headPortrait.png" alt="头像" class="user-img" /> -->
          <iconpark-icon :name="isTD ? 'operation' : 'owner'" size="34"></iconpark-icon>
          <el-tooltip
            class="item"
            effect="light"
            :content="userInfo.name + ' ' + curOrganizationName"
            placement="top-start"
          >
            <div tabindex="-1" @click="handleShowPanel" @blur="hidePanel" class="user-name">
              <span class="userSpan">{{ userInfo.aliasName || userInfo.name || "-" }}</span>
              <!-- <span class="orgSpan">{{ curOrganizationName || "ABB OIC运维服务公司" }}</span> -->
              <!-- <el-tooltip class="item" effect="light" :content="curOrganizationName || '-'" placement="top-start"> -->
              <span class="orgSpan">{{ curOrganizationName || "-" }}</span>
              <!-- </el-tooltip> -->
            </div>
          </el-tooltip>
          <el-cascader-panel
            class="user-panel"
            v-if="isPanelShow"
            :props="{
              expandTrigger: 'hover',
            }"
            :options="options"
            @mousedown.native="handleClick"
          >
            <!-- @change="handlePanelChange" -->
            <template slot-scope="{ data }">
              <div
                class="panel-row"
                :class="data.role ? 'twoLine' : 'singleLine'"
                :data-isTarget="data.isTarget"
                :data-parent="data.parent"
                :data-key="data.value"
              >
                <div class="orgLine">
                  <iconpark-icon v-if="data.icon" :name="data.icon" size="24" style="color: #3366ff"></iconpark-icon
                  >{{ data.label }}
                </div>
                <div v-if="data.role" class="roleLine">
                  <span v-if="data.role.includes('organization_owner')">管理员 </span>
                  <span v-if="data.role.includes('organization_employee')">工程师 </span>
                  <!-- <span v-if="data.role.includes('organization_customer')">客户 </span> -->
                </div>
                <!-- <i class="el-icon-close" v-if="data.isTarget"></i> -->
                <iconpark-icon
                  name="checkmark"
                  class="checkIcon"
                  size="14"
                  v-if="data.isTarget"
                  stroke="#3366FF"
                ></iconpark-icon>
              </div>
            </template>
          </el-cascader-panel>
          <iconpark-icon name="earth" size="24"></iconpark-icon>
          <span class="user-language">{{ curLanguage }}</span>
          <!-- <el-dropdown @command="handleChangeLanguage">
            <i class="el-icon-arrow-down user-arrow"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="ZH">ZH</el-dropdown-item>
              <el-dropdown-item command="English">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
    </div>
    <div class="msgs-container" v-if="pushItemList.length && curmenus !== 'role'">
      <div class="msgs-list">
        <msgPushItem
          :itemProps="item"
          v-for="(item, index) in pushItemList"
          :key="index"
          @deleteItem="deleteMsgItem"
        ></msgPushItem>
      </div>
    </div>
    <div class="msp-content">
      <router-view />
    </div>
    <!-- 【邀请客户】弹框 -->
    <el-dialog
      title="邀请客户"
      :visible.sync="inviteDialogVisible"
      custom-class="inviteDialog"
      :close-on-click-modal="false"
    >
      <div class="formBox">
        <el-form ref="form" :inline="false">
          <el-form-item label="请输入您客户的邮箱地址">
            <el-input v-model="invitationEmail" placeholder="邮箱地址"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sendInvitation">发送</el-button>
      </div>
    </el-dialog>
    <!-- 【创建运维公司】 -->
    <el-dialog
      title="创建运维公司"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="companyHandleClose"
      :close-on-click-modal="false"
      :fullscreen="false"
      custom-class="companyDialog"
      v-loading="companyLoading"
    >
      <el-form ref="form" :model="form" :rules="addRules" label-width="80px">
        <el-form-item label="公司名:" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="电话号码:" prop="phone">
          <el-input v-model="form.phone" placeholder="例：0755-88888888 或者 13855556666 任意一种"></el-input>
        </el-form-item>
        <el-form-item label="国家:" prop="country">
          <el-select v-model="form.country" @change="getRegionData('country', $event)" placeholder="请选择国家">
            <el-option
              v-for="country in countries"
              :label="country.label"
              :value="country.value"
              :key="country.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="省份:" prop="province">
          <el-select v-model="form.province" @change="getRegionData('province', $event)" placeholder="请选择省份">
            <el-option
              v-for="province in provinces"
              :label="province.label"
              :value="province.value"
              :key="province.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市:" prop="city">
          <el-select v-model="form.city" @change="getRegionData('city', $event)" placeholder="请选择城市">
            <el-option v-for="city in cities" :label="city.label" :value="city.value" :key="city.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址:" prop="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="邮编:" prop="postcode">
          <el-input v-model="form.postcode"></el-input>
        </el-form-item>
        <el-form-item label="描述:" prop="description">
          <el-input type="textarea" v-model="form.description" :placeholder="createOrgRemarkPlaceholder"></el-input>
        </el-form-item>
        <!-- <el-form-item label="LOGO:" prop="logo" class="logo-line">
          <el-upload
            ref="uploadFile"
            class="avatar-uploader"
            action=""
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
            :http-request="uploadLogo"
            :multiple="false"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-circle-plus avatar-uploader-icon"></i>
          </el-upload>
          <span class="logo-tip">最大不超过1MB，支持JPG、PNG</span>
        </el-form-item> -->
      </el-form>
      <div class="applicationTip font-f-rg">
        备注：申请需要经过ABB后台人工审核，审核通过后，您可以邀请工程师和客户进入您所创建的组织，来进行运维活动
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCreateOrganization">发送</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouteConfig, RouteRecord } from "vue-router";
import { namespace } from "vuex-class";
import { getLoginStatus, loginout, getCurrentVersion } from "@/api/auth";
import { getUserRole, getUnreadMsgCount } from "@/api/tdHome";
import { availableOrganizations, getBoOrganization, createOrganizations } from "@/api/boHome";
import { invitateCustomer } from "@/api/custom";
import LocalStorage from "@/utils/localStorage";
import PushService from "@/pushService/index";
import msgPushItem from "@/components/common/msgPushItem.vue";
import eventBus from "@/utils/eventBus";
import regionData from "@/utils/regionData";

const userinfo = namespace("userInfo");

@Component({
  components: {
    msgPushItem,
  },
})
export default class Layout extends Vue {
  @userinfo.Getter("getOwner") getOwner!: string;
  @userinfo.Getter("getOrganizations") getOrganizations!: { curOrg: { [key: string]: string }; list: [] };
  @userinfo.Action("setOwner") setOwner!: any;
  @userinfo.Action("setUserInfo") setUserInfo!: any;
  @userinfo.Action("setOrganizations") setOrganizations!: any;
  @userinfo.Action("setUserRole") setUserRole!: any;
  @userinfo.Getter("getUserInfo") getUserInfo!: any;

  menus: { [key: string]: any }[] = [];

  curmenus = "TDhome";

  curLanguage = "ZH";

  curRole = "";
  version = "";

  organizationsList: any[] = [];
  hasOrganizations: any = true;
  dialogVisible = false;
  companyLoading = false;
  provinces: { label: string; value: string }[] = [];
  cities: { label: string; value: string }[] = [];
  userId = "";
  haveApplied = false;
  countries = [
    {
      label: "中国",
      value: "86",
    },
  ];

  form = {
    name: "",
    address: "",
    postcode: "",
    description: "",
    country: "86",
    province: "",
    city: "",
    phone: "",
    email: "",
    logo: "",
  };

  isTD = true;
  curOrganizationName = ""; //当前组织名称
  curOrganizationId: any = ""; //当前组织ID

  totalUnreadMsgCount = 0;

  // 邀请客户
  inviteDialogVisible = false;
  invitationEmail = "";
  inviteFromOrgId = "";
  mspTitle = "";

  isPanelShow = false;
  options = [
    {
      value: "owner",
      label: "切换至业主端/运维端",
      icon: "user",
    },
    {
      value: "organization",
      label: "切换组织",
      children: [
        {
          parent: "organization",
          value: "company1",
          label: "公司1",
          role: ["organization_employee"],
          isTarget: false,
        },
        {
          parent: "organization",
          value: "company2",
          label: "公司2",
          role: ["organization_employee"],
          isTarget: true,
        },
      ],
      icon: "switch",
    },
    {
      value: "profile",
      label: "个人资料",
      icon: "user-card",
    },
    {
      value: "loginOut",
      label: "登出",
      icon: "out",
    },
  ];

  userRoles: { [key: string]: string }[] = [];
  createOrgRemarkPlaceholder =
    "请认真填写贵公司主要的经营业务，这里会展示给ABB管理员以便审核，同时后续也会展示给贵公司的客户。";

  mspRouter = [];
  pushService = new PushService();
  pushItemList: any[] = [];

  realPushList: any[] = []; //是从后端推上来的真实数据
  localPushList: any[] = []; //是本地为了处理业务添加的数据

  localStorageModule = new LocalStorage();

  userInfo: { [key: string]: any } = {
    name: "",
  };

  addRules = {
    name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
    phone: [
      { required: true, message: "请输入联系方式", trigger: "blur" },
      {
        validator: this.contactRules,
        trigger: "blur",
      },
    ],
    country: [{ required: true, message: "请选择国家", trigger: "blur" }],
    province: [{ required: true, message: "请选择省份", trigger: "blur" }],
    city: [{ required: true, message: "请选择城市", trigger: "blur" }],
    address: [{ max: 255, message: "不能超过255个字符", trigger: "blur" }],
    postcode: [{ validator: this.postcodeRules, trigger: "blur" }],
    description: [{ required: true, message: "请输入描述内容", trigger: "blur" }],
  };

  contactRules(rule: any, value: any, callback: any) {
    const RegExp = /^((1[3456789]\d{9})|(\d{3}-\d{7,8}|\d{4}-\d{7,8}))$/;
    if (RegExp.test(value)) {
      callback();
    } else {
      callback(new Error("电话号码/手机号码，格式不正确!"));
    }
  }

  postcodeRules(rule: any, value: any, callback: any) {
    if (value) {
      const valueStr = value + "";
      if (valueStr.length === 6) {
        const valueNum = Number(value);
        if (valueNum) {
          if (valueNum % 1 === 0) {
            if (valueNum > 99999 && valueNum < 1000000) {
              callback();
            } else {
              callback(new Error("邮编号码可以为:空/6位数字!"));
            }
          } else {
            callback(new Error("邮编号码可以为:空/6位数字!"));
          }
        } else {
          callback(new Error("邮编号码可以为:空/6位数字!"));
        }
      } else {
        callback(new Error("邮编号码可以为:空/6位数字!"));
      }
    } else {
      callback();
    }
  }

  //  监听切换用户身份和url变化时
  @Watch("$route", { immediate: true })
  onRouterChanged(newV: RouteRecord, oldVal: RouteRecord) {
    const strList = newV.path.split("/");
    this.curmenus = strList[2];
    if (this.curmenus === "role") {
      this.options = [
        {
          value: "profile",
          label: "个人资料",
          icon: "user-card",
        },
        {
          value: "loginOut",
          label: "登出",
          icon: "out",
        },
      ];
    } else if (oldVal && oldVal.path === "/msp/role") {
      this.options = [
        {
          value: "owner",
          label: this.isTD ? "切换至业主端" : "切换至运维端",
          icon: "user",
        },
        {
          value: "organization",
          label: "切换组织",
          children: [],
          icon: "switch",
        },
        {
          value: "profile",
          label: "个人资料",
          icon: "user-card",
        },
        {
          value: "loginOut",
          label: "登出",
          icon: "out",
        },
      ];
    }
  }

  //  监听身份变化 跳转不同的页面
  @Watch("getOwner")
  onGetOwnerChanged(newV: string, oldV: string) {
    if (!newV) {
      this.toPage("role");
      return;
    }
    this.curRole = newV;
    this.pushItemList = [];
    if (newV === "employee") {
      this.menus = this.mspRouter?.filter((item: any) => item.isTD);
      this.isTD = true;
      this.options[0].label = "切换至业主端";
      if (oldV === "customer" || this.curmenus === "role") {
        this.toPage("TDhome");
      } else {
        this.toPage(this.curmenus);
      }
      this.hasOrganizations = true;
    } else if (newV === "customer") {
      this.menus = this.mspRouter?.filter((item: any) => !item.isTD);
      this.isTD = false;
      this.options[0].label = "切换至运维端";
      if (oldV === "employee" || this.curmenus === "role") {
        this.localStorageModule.setItem({
          key: "_index",
          value: "0",
          validity: 1000 * 60 * 60 * 24 * 30,
        });
        this.toPage("BOhome");
      } else {
        this.toPage(this.curmenus);
      }
      this.pushItemList.unshift({
        organizationId: "",
        userId: "",
        timestamp: "",
        type: "",
        title: "欢迎使用远程运维系统。",
        payload: {
          title: "欢迎使用远程运维系统。",
          subTitle: "欢迎使用远程运维系统。",
          msgType: "maintenance",
          iconType: "warning",
        },
        sortType: "",
        text: "欢迎使用远程运维系统。",
        btnList: [
          {
            text: "好的，我知道了",
            params: {},
          },
        ],
        isCanDelete: true,
      });
    }

    this.changeRoleFn(newV);
  }

  @Watch("hasOrganizations", { deep: true })
  onOrganizationCountChanged(newVal: any) {
    if (newVal) {
      const targetIndex = this.pushItemList.findIndex((item: { [key: string]: any }) => {
        return item.title === "创建组织";
      });
      if (targetIndex !== -1) {
        this.pushItemList.splice(targetIndex, 1);
      }
    } else {
      if (this.curRole === "employee") {
        this.pushItemList.unshift({
          organizationId: "",
          userId: "",
          timestamp: "2022-12-01T03:42:12Z",
          type: "maintenance",
          title: "创建组织",
          payload: {
            title: "创建组织",
            subTitle: "您尚未加入或创建任何运维公司，请先创建您的公司或联系您的管理员邀请您加入。",
            msgType: "maintenance",
            iconType: "warning",
          },
          sortType: "",
          text: "您尚未加入或创建任何运维公司，请先创建您的公司或联系您的管理员邀请您加入。",
          btnList: [
            {
              text: "创建公司",
              params: {},
            },
          ],
          isCanDelete: false,
        });
      }
    }
  }

  @Watch("getOrganizations.list", { immediate: true, deep: true })
  onorganizationsChanged(newVal: { [key: string]: string }[] | undefined) {
    const tempArr = [];
    if (newVal?.length) {
      this.organizationsList = Object.assign([], newVal);
      this.hasOrganizations = true;
      for (const org of newVal) {
        tempArr.push({
          label: org.name,
          value: org.uuid,
          role: org.role,
          parent: "organization",
          isTarget: org.isTarget,
        });
      }
    } else {
      if (newVal !== undefined) {
        this.hasOrganizations = false;
      }
    }
    const orgIndex = this.options.findIndex((item: { [key: string]: any }) => {
      return item.value === "organization";
    });
    if (orgIndex !== -1) {
      this.options[orgIndex].children = Object.assign([], tempArr);
    }
  }

  @Watch("getOrganizations.curOrg", { deep: true })
  oncurOrganizationsChanged(newVal: { [key: string]: any } | undefined) {
    this.curOrganizationName = newVal?.name || "";
    this.curOrganizationId = newVal?.uuid || "";
    if (newVal?.uuid) {
      this.userInfo.aliasName = newVal.employee?.aliasName || "";
    }
  }

  @Watch("pushService.notifications", { deep: true })
  onNotificationsChanged(newV: []) {
    console.log("pushService.notifications", newV);
    const newList = newV.map((item: { [key: string]: any }) => {
      return JSON.parse(item.value);
    });
    this.realPushList = newList.map((item) => {
      let list: any[] = [];
      const payload = item.payload;
      const btnList = [];
      let sortType = "";
      if (payload.operation) {
        const operations = payload.operation;
        sortType = operations[0].url?.split("?")[0] || "";
        for (const item of operations) {
          const paramData: { [key: string]: any } = {};
          const paramsString = item?.url || "";
          const params = paramsString.split("?")[1];
          const allParams = decodeURIComponent(params).split("&");
          for (const paramItem of allParams) {
            const [key, val] = paramItem.split("=");
            paramData[key] = val;
          }
          if (item.text !== "申请运维" && item.text !== "修改运维" && item.text !== "需要运维") {
            btnList.push({ text: item.text, params: paramData });
          }
        }
      } else {
        btnList.push({
          text: "好的，我知道了",
          params: {},
        });
      }
      if (payload.title === "创建公司申请通过" || payload.title === "解除合作") {
        this.getOrganizationList(this.curRole);
      }
      return {
        ...item,
        title: payload.title,
        text: payload.subTitle,
        sortType: sortType,
        btnList: btnList,
        isCanDelete: true,
      };
    });
    const isApplyFailed = this.realPushList.some((item: any) => {
      return item.payload.title === "创建公司审批-未通过";
    });
    if (isApplyFailed) {
      if (this.curRole === "employee") {
        this.localPushList.push({
          organizationId: "",
          userId: "",
          timestamp: "2022-12-01T03:42:12Z",
          type: "maintenance",
          title: "创建组织",
          payload: {
            title: "创建组织",
            subTitle: "您尚未加入或创建任何运维公司，请先创建您的公司或联系您的管理员邀请您加入。",
            msgType: "maintenance",
            iconType: "warning",
          },
          sortType: "",
          text: "您尚未加入或创建任何运维公司，请先创建您的公司或联系您的管理员邀请您加入。",
          btnList: [
            {
              text: "创建公司",
              params: {},
            },
          ],
          isCanDelete: false,
        });
      }
    }

    // 如果最新的提示为被公司移除，则重新获取组织列表
    const totle = this.realPushList.length;
    if (totle) {
      const lastItem = this.realPushList[totle - 1];
      if (lastItem.title === "从公司移除") {
        this.getOrganizationList("employee");
      }
    }

    this.pushItemList = [...this.localPushList, ...this.realPushList];
  }

  @Watch("getUserInfo")
  onUserInfoChanged(newVal: any) {
    if (newVal && newVal.sub) {
      this.userId = newVal.sub;
      this.userInfo = newVal;
    }
  }

  @Watch("curOrganizationId")
  async oncurOrganizationIdChanged(newVal: any) {
    this.totalUnreadMsgCount = await getUnreadMsgCount(this.curOrganizationId);
  }

  async created() {
    this.pushService.doConnect();

    //  从localStorage获取身份并存储到vuex
    const roleType = this.localStorageModule.getItem("roleType") || "employee";
    if (roleType) {
      this.setOwner(roleType);
    }

    this.getUserInfos();
    const router = this.$router.options.routes as any;
    const navRouter = router.find((item: any) => item.path === "/msp")?.children;
    this.mspRouter = navRouter.filter((item: any) => item.isMenus);
    // this.userRoles = await getUserRole();
    // await this.getOrganizationList(roleType);
    // this.onGetOwnerChanged(this.getOwner);

    this.version = await getCurrentVersion(); //获取版本信息

    eventBus.$on("inviteCustomer", (params: { organizationId: string; title: string }) => {
      this.inviteDialogVisible = true;
      this.inviteFromOrgId = params.organizationId;
      this.mspTitle = params.title;
    });

    eventBus.$on("createOrganization", () => {
      this.dialogVisible = true;
      this.$nextTick(() => {
        const provinceList = [];
        const cityList = [];
        const provinceData = regionData["86"];
        for (const key in provinceData) {
          provinceList.push({
            label: provinceData[key],
            value: key,
          });
        }
        this.provinces = provinceList;
        const provinceIds = Object.keys(provinceData);
        this.form.province = provinceIds[0];
        const cityData = regionData[provinceIds[0]];
        for (const key in cityData) {
          cityList.push({
            label: cityData[key],
            value: key,
          });
        }
        this.cities = cityList;
        const cityIds = Object.keys(cityData);
        this.form.city = cityIds[0];
      });
    });

    eventBus.$on("unreadMsgChange", async () => {
      this.totalUnreadMsgCount = await getUnreadMsgCount(this.curOrganizationId);
    });
  }

  async changeRoleFn(type: string) {
    this.userRoles = await getUserRole();
    await this.getOrganizationList(type);
  }

  async getUserInfos() {
    try {
      const { userInfo } = await getLoginStatus();
      this.userInfo = userInfo;
      this.setUserInfo(userInfo);
    } catch (error) {
      console.error(error);
    }
  }

  async getOrganizationList(type: string) {
    let request: any;
    let organizations: { [key: string]: any }[] = [];
    if (type === "employee") {
      request = await availableOrganizations();
    } else if (type === "customer") {
      request = await getBoOrganization();
    }
    this.userRoles = await getUserRole();
    try {
      organizations = request.map((orgItem: { [key: string]: any }) => {
        const roleList =
          this.userRoles.filter((roleItem: { [key: string]: any }) => {
            return roleItem.organizationId === orgItem.uuid;
          }) || {};
        const roles = roleList.map((roleItem: any) => {
          return roleItem.role;
        });
        return { ...orgItem, role: roles, isTarget: false };
      });
      let index = 0;
      if (type === "employee") {
        const _index = this.localStorageModule.getItem("_index");
        if (_index) {
          index = Number(_index);
          if (index > organizations.length - 1) {
            index = 0;
            this.localStorageModule.setItem({
              key: "_index",
              value: "0",
              validity: 1000 * 60 * 60 * 24 * 30,
            });
          }
        } else {
          this.localStorageModule.setItem({
            key: "_index",
            value: "0",
            validity: 1000 * 60 * 60 * 24 * 30,
          });
        }
      }
      if (organizations[index]) {
        this.curOrganizationName = organizations[index].name || "";
        this.curOrganizationId = organizations[index].uuid || "";
        organizations[index].isTarget = true;
      } else {
        this.curOrganizationName = "";
        this.curOrganizationId = "";
        this.totalUnreadMsgCount = await getUnreadMsgCount("");
      }
      this.setOrganizations({ curOrg: organizations[index] || "", list: organizations });
      let nowUserInfos: { [key: string]: string }[] = [];
      if (organizations.length) {
        nowUserInfos = this.userRoles.filter((item: { [key: string]: string }) => {
          return item.organizationId === organizations[index].uuid;
        });
      }
      this.setUserRole(
        nowUserInfos.map((item) => {
          return item.role;
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  BackToHome() {
    location.href = "/";
  }

  async toPage(name: string) {
    if (this.curmenus === name) return;
    this.curmenus = name;
    this.$router.push({ name });
  }

  handleShowPanel(): void {
    this.isPanelShow = !this.isPanelShow;
  }

  hidePanel() {
    this.isPanelShow = false;
  }

  async handleClick(e: any) {
    const targetEl = e.target;
    const topEl = targetEl.closest(".el-cascader-node");
    const keyEl = topEl.querySelector(".panel-row");
    const parentKey = keyEl.dataset.parent;
    const key = keyEl.dataset.key;
    if (parentKey) {
      if (parentKey === "organization") {
        const orgId = key;
        const tempArr: any[] = this.organizationsList.filter((item: { [key: string]: any }) => {
          return item.uuid === orgId;
        });
        const index = this.organizationsList.findIndex((item: { [key: string]: any }) => {
          return item.uuid === orgId;
        });
        this.organizationsList.forEach((item: { [key: string]: any }) => {
          item.isTarget = false;
        });
        this.organizationsList[index].isTarget = true;
        this.setOrganizations({ curOrg: tempArr[0], list: this.organizationsList });
        if (tempArr[0]) {
          this.curOrganizationName = tempArr[0].name || "";
        }
        this.localStorageModule.setItem({
          key: "_index",
          value: index + "",
          validity: 1000 * 60 * 60 * 24 * 30,
        });
        if (this.userRoles.length !== this.organizationsList.length) {
          //判断是否此时是点击新加入的在组织
          this.userRoles = await getUserRole();
        }
        const nowUserInfos: { [key: string]: string }[] = this.userRoles.filter((item: { [key: string]: string }) => {
          return item.organizationId === orgId;
        });
        this.setUserRole(
          nowUserInfos.map((item) => {
            return item.role;
          })
        );
        if (this.$route.name === "site" || this.$route.name === "TDdevice") {
          this.$router.push({ name: "customer" });
        }
        if (this.$route.name === "community") {
          this.$router.push({ name: "community" });
        }
      }
    } else {
      if (key === "owner") {
        const curOwner = this.getOwner;
        if (curOwner === "employee") {
          this.setOwner("customer");
          this.localStorageModule.setItem({
            key: "roleType",
            value: "customer",
            validity: 1000 * 60 * 60 * 24 * 30,
          });
        } else {
          this.setOwner("employee");
          this.localStorageModule.setItem({
            key: "roleType",
            value: "employee",
            validity: 1000 * 60 * 60 * 24 * 30,
          });
        }
      } else if (key === "loginOut") {
        window.location.href = "/api/loginout";
      } else if (key === "profile") {
        window.open("https://myportal.abb.com/home");
      }
    }
    this.isPanelShow = false;
  }

  // async handlePanelChange(node: string[]): Promise<void> {
  //   if (node[0] === "owner") {
  //     const curOwner = this.getOwner;
  //     if (curOwner === "employee") {
  //       this.setOwner("customer");
  //       this.localStorageModule.setItem({
  //         key: "roleType",
  //         value: "customer",
  //         validity: 1000 * 60 * 60 * 24 * 30,
  //       });
  //     } else {
  //       this.setOwner("employee");
  //       this.localStorageModule.setItem({
  //         key: "roleType",
  //         value: "employee",
  //         validity: 1000 * 60 * 60 * 24 * 30,
  //       });
  //     }
  //   }

  //   if (node[0] === "loginOut") {
  //     window.location.href = "/api/loginout";
  //   }

  //   if (node[0] === "organization") {
  //     const orgId = node[1];
  //     const tempArr: any[] = this.organizationsList.filter((item: { [key: string]: string }) => {
  //       return item.uuid === orgId;
  //     });
  //     const index = this.organizationsList.findIndex((item: { [key: string]: string }) => {
  //       return item.uuid === orgId;
  //     });
  //     this.setOrganizations({ curOrg: tempArr[0], list: this.organizationsList });
  //     if (tempArr[0]) {
  //       this.curOrganizationName = tempArr[0].name || "";
  //     }
  //     this.localStorageModule.setItem({
  //       key: "_index",
  //       value: index + "",
  //       validity: 1000 * 60 * 60 * 24 * 30,
  //     });
  //     const nowUserInfos: { [key: string]: string }[] = this.userRoles.filter((item: { [key: string]: string }) => {
  //       return item.organizationId === orgId;
  //     });
  //     this.setUserRole(
  //       nowUserInfos.map((item) => {
  //         return item.role;
  //       })
  //     );
  //     if (this.$route.name === "site" || this.$route.name === "TDdevice") {
  //       this.$router.push({ name: "customer" });
  //     }
  //   }

  //   if (node[0] === "profile") {
  //     window.open("https://myportal.abb.com/home");
  //   }
  //   this.isPanelShow = false;
  // }

  async sendInvitation() {
    let result = await invitateCustomer(this.inviteFromOrgId, this.invitationEmail);
    this.inviteDialogVisible = false;
    if (result !== false) {
      this.deleteMsgItem(this.mspTitle);
      this.$message({
        message: "客户邀请成功",
        type: "success",
        offset: 90,
      });
    } else {
      this.$message({
        message: "您邀请的客户已经被邀请或者邮箱输入有问题",
        type: "error",
        offset: 90,
      });
    }
  }

  handleChangeLanguage(command: string) {
    this.curLanguage = command;
    this.$message({
      message: "当前语言切换为" + command,
      type: "success",
      offset: 90,
    });
  }

  deleteMsgItem(title: string) {
    const targetIndex1 = this.realPushList.findIndex((item: { [key: string]: any }) => {
      return item.payload.title === title;
    });
    const targetIndex2 = this.localPushList.findIndex((item: { [key: string]: any }) => {
      return item.payload.title === title;
    });
    if (targetIndex1 !== -1) {
      this.realPushList.splice(targetIndex1, 1);
    }
    if (targetIndex2 != -1) {
      this.localPushList.splice(targetIndex1, 1);
    }
    this.pushItemList = this.localPushList.concat(this.realPushList);
    this.pushService.deleteMsg(title);
  }

  companyHandleClose(done: any) {
    done();
  }

  getRegionData(key: string, val: string) {
    if (key === "province") {
      const citiesData = regionData[val];
      const cityList = [];
      for (const key in citiesData) {
        cityList.push({
          label: citiesData[key],
          value: key,
        });
      }
      const cityIds = Object.keys(citiesData);
      this.form.city = cityIds[0];
      this.cities = cityList;
    }
  }

  //  创建公司
  async handleCreateOrganization(): Promise<void> {
    const formEle = this.$refs.form as HTMLFormElement;
    formEle.validate(async (valid: boolean, infos: any) => {
      if (valid) {
        this.companyLoading = true;
        const headers = {
          "X-MSP-Name": this.getUserInfo.name,
          "X-MSP-Email": this.getUserInfo.email,
        };
        const country = regionData["00"][this.form.country] || this.form.country;
        const province = regionData["86"][this.form.province] || this.form.province;
        const provinceEntries = Object.entries(regionData["86"]).filter(([key, value]) => {
          return value === province;
        });
        const provinceCode = provinceEntries[0][0];
        const city = regionData[provinceCode][this.form.city] || this.form.city;
        Object.assign(this.form, { country, province, city });
        this.form.email = this.getUserInfo.email;
        try {
          const res = await createOrganizations(this.form, headers);
          this.companyLoading = false;
          this.dialogVisible = false;
          if (res !== false) {
            this.haveApplied = true;
          }
          this.$message({
            message: res !== false ? "创建公司申请已提交，请等待审核" : "创建公司申请提交失败",
            type: res !== false ? "success" : "error",
            offset: 90,
          });
          this.deleteMsgItem("创建组织");
          // location.reload();
        } catch (error) {
          console.error(error);
        }
      } else {
        this.$message({
          message: "必填项不能为空！",
          type: "warning",
          offset: 90,
        });
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.msp-layout {
  margin-left: calc(100vw - 100%);
}
.msp-header {
  height: 72px;
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 5px rgba(219, 219, 219, 0.3);
  color: #111;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.msgs-container {
  width: 100%;
  padding-top: 16px;
  background-color: #fafafa;
  .msgs-list {
    width: 1200px;
    height: 54px;
    margin: 0 auto;
  }
}

.msp-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
}

.logo {
  display: flex;
  align-items: center;

  img {
    width: 76px;
    height: 30px;
    cursor: pointer;
  }

  .line {
    margin: 0 18px;
    height: 30px;
    width: 1px;
    background-image: linear-gradient(#3366ff, #ff000f);
  }

  .text {
    width: 300px;
    line-height: 22px;
    text-align: left;
    display: flex;
    flex-direction: column;
    .versionSpan {
      margin-left: 3px;
    }
    .typeSpan {
      font-size: 12px;
      display: inline-block;
      height: 16px;
      line-height: 18px;
      margin-left: 8px;
      width: 48px;
      text-align: center;
      border-radius: 2px;
    }
    .tdTypeSpan {
      color: #fa8a2e;
      background: rgba(250, 138, 46, 0.08);
    }
    .boTypeSpan {
      color: #0ca919;
      background: rgba(12, 169, 25, 0.08);
    }
  }
}

.user {
  position: relative;
  display: flex;
  width: 330px;
  justify-content: flex-end;
  align-items: center;
  color: #000;
  font-size: 16px;

  &-img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 12px;
  }

  &-name {
    // margin-right: 36px;
    margin-right: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > span {
      // width: 124px;
      max-width: 188px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
    .orgSpan {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      // max-width: 180px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
  }

  &-panel {
    position: absolute;
    // left: -100px;
    left: -56px;
    top: 40px;
    background: #fff;
    text-align: left;
    // height: 148px;
    z-index: 10;
  }

  /deep/ .el-cascader-menu {
    &:first-child {
      .el-cascader-node {
        height: 42px;
      }
    }
    &:nth-child(2) {
      .el-cascader-node {
        height: 54px;
      }
    }
    .el-cascader-menu__wrap {
      // height: auto;
      height: 100%;
    }
  }

  .panel-row {
    display: flex;
    flex-direction: column;
    position: relative;
    .orgLine {
      display: flex;
      align-items: center;
    }
    &.singleLine {
      // height: 42px;
      .orgLine {
        font-size: 14px;
        line-height: 22px;
      }
    }
    &.twoLine {
      // height: 54px;
      .orgLine {
        font-size: 14px;
        line-height: 22px;
      }
      .roleLine {
        font-size: 12px;
        line-height: 12px;
        color: #9f9f9f;
      }
      &[data-isTarget="true"] {
        color: #3366ff;
      }
    }
    .checkIcon {
      position: absolute;
      top: 8px;
      right: -13px;
    }
  }

  /deep/ {
    .el-cascader-node {
      padding: 0 20px 0 0;
    }

    .el-icon-check {
      display: none;
    }
  }

  &-language {
    width: 70px;
  }
}

.user-arrow {
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.msp-content {
  background: #fafafa;
  min-height: calc(100vh - 72px);
  display: flex;
  justify-content: center;
}

.menu-name {
  height: 42px;
  line-height: 42px;
  width: 56px;
  margin: 0 18px;
  cursor: pointer;
  position: relative;
  .countTip {
    position: absolute;
    top: 6px;
    right: 2px;
    width: 8px;
    height: 8px;
    background: #ff000f;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
  }
}

.active {
  border-bottom: 2px solid #ff000f;
  color: #ff000f;
}

/deep/ .inviteDialog.el-dialog {
  width: 420px;
  .formBox {
    padding: 0px 18px;
    height: 108px;
    .el-form-item__label {
      font-size: 12px;
      color: #000;
    }
  }
}

/deep/ .el-dialog.companyDialog {
  width: 560px !important;
  .el-dialog__body {
    width: 560px;
    height: 486px;
    overflow-y: scroll;
    .el-form {
      padding: 0px 0px 0px 54px;
      .el-form-item {
        width: 431px;
        margin-bottom: 18px;
        .el-input__inner {
          height: 30px;
        }
        .line {
          text-align: center;
        }
        .el-select {
          width: 100%;
        }
        .avatar-uploader {
          .el-upload {
            // border: 1px dashed #d9d9d9;
            // border-radius: 6px;
            width: 120px;
            height: 120px;
            border: 1px dashed #dcdcdc;
            border-radius: 2px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            &:hover {
              border-color: #409eff;
            }
            .avatar {
              width: 120px;
              height: 120px;
              display: block;
            }
            .avatar-uploader-icon {
              font-size: 18px;
              color: #3366ff;
              width: 120px;
              height: 120px;
              line-height: 120px;
              text-align: center;
              position: relative;
              top: -12px;
            }
          }
        }
        &.logo-line {
          .el-form-item__content {
            display: flex;
            align-items: center;
            .logo-tip {
              color: #9f9f9f;
              font-size: 12px;
              line-height: 18px;
              margin-left: 12px;
            }
          }
        }
      }
    }
    .applicationTip {
      height: 36px;
      font-size: 12px;
      line-height: 18px;
      color: #9f9f9f;
      padding: 0px 18px 18px 18px;
    }
  }
  .el-dialog__footer {
    width: 560px;
    .el-button {
      background: #3366ff;
    }
  }
}
</style>
