import request from "@/axios/http";

//  获取消息中心信息
export const getMessageCenterList = (params: any) => {
  return request.get(`/api/message/list/get`, params);
};

//  已读单条信息
export const readMessageById = (messageId: string) => {
  return request.put(`/api/message/read/${messageId}/put`, {});
};

//  已读全部信息
export const readMessageAll = (params: any) => {
  return request.put(`/api/message/readall/put`, params);
};

//  删除单条信息
export const delMessageById = (messageId: string) => {
  return request.del(`/api/message/${messageId}/delete`, {});
};

//  删除全部信息
export const delMessageAll = (params: any) => {
  return request.del(`/api/message/deleteall`, params);
};

// 接受邀请
export const invitationAccept = async (params: any) => {
  try {
    return await request.post(`/api/invitations/accept/post`, params);
  } catch (error) {
    return false;
  }
};

// 拒绝邀请
export const invitationDecline = async (params: any) => {
  try {
    return await request.post(`/api/invitations/declined/post`, params);
  } catch (error) {
    return false;
  }
};

// BO运维申请-接受
export const acceptAccessRequestBO = async (organizationId: string, requestId: string) => {
  try {
    return await request.post(
      `/api/organizations/${organizationId}/customer/access-requests/${requestId}/accept/post`,
      {}
    );
  } catch (error: any) {
    if (error.response.status === 409) {
      return error.response.data;
    } else {
      return false;
    }
  }
};

// BO运维申请-拒绝
export const declineAccessRequestBO = async (organizationId: string, requestId: string) => {
  try {
    return await request.post(
      `/api/organizations/${organizationId}/customer/access-requests/${requestId}/decline/post`,
      {}
    );
  } catch (error) {
    return false;
  }
};

//  标记已读信息(单个)
export const markMessage = (deviceId: string) => {
  return request.put(`/api/read/alert/put`, {
    deviceId,
  });
};

//  清除设备告警信息
export const markAllWarningMsg = () => {
  return request.put(`/api/read/all/alert/put`, {});
};
