<template>
  <div class="message-push-item">
    <iconpark-icon name="info-circle" color="#3366FF"></iconpark-icon>
    <span class="message-push-title">{{ itemProps.text }}</span>
    <span class="message-push-btntext">
      <span v-for="(btn, index) in itemProps.btnList" :key="index" @click="operationFn(btn)" class="fontBtn">{{
        btn.text
      }}</span>
      <i class="el-icon-close" style="margin-left: 22px" @click="deleteMsg" v-if="itemProps.isCanDelete"></i>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { formatTime } from "@/utils";
import { namespace } from "vuex-class";
import LocalStorage from "@/utils/localStorage";
import { getUserRole } from "@/api/tdHome";
import { getCustomerDeteailByCustomerId } from "@/api/custom";
import { acceptAccessRequestBO, declineAccessRequestBO } from "@/api/message";
import eventBus from "@/utils/eventBus";
const userinfo = namespace("userInfo");

@Component
export default class MsgPushItem extends Vue {
  @userinfo.Getter("getOwner") getOwner!: string;
  @userinfo.Action("setOrganizations") setOrganizations!: any;
  @Prop({
    default: {
      // id: "",
      // usagePlace: "", //   'msgCenter' || 'mspPush'
      // msgType: "", //  'notification' || 'maintenance'
      // sort: "", //  'invitations' || ...
      // title: "",
      // subTitle: "",
      // status: "",
      // params: "",
      // timestamp: "",
      // taskUrl: "",
      // collapse: false,
      // hasTask: false,
      organizationId: "",
      userId: "",
      timestamp: "",
      type: "",
      payload: { title: "", subTitle: "", msgType: "", iconType: "", operation: [] },
      title: "",
      text: "",
      btnList: [],
      sortType: "",
      isCanDelete: true,
    },
  })
  itemProps!: { [key: string]: any };

  deleteMsg() {
    this.$emit("deleteItem", this.itemProps.payload.title);
  }

  async operationFn(btn: { [key: string]: any }) {
    const sortType = this.itemProps.sortType;
    const btnText = btn.text;
    if (sortType) {
      if (sortType === "sessions") {
        if (btnText === "点击这里获取您的任务") {
          const customerId = btn.params.cuuid;
          const targetOrgId = this.itemProps.organizationId;
          const res = await getCustomerDeteailByCustomerId(targetOrgId, customerId);
          // this.$router.push({ name: "site", params: { id: customerId, customerName: res.name } });
          const params = { id: customerId, customerName: res.name, organizationId: targetOrgId };
          const encodeStr = encodeURIComponent(JSON.stringify(params));
          const btoaStr = window.btoa(encodeStr);
          if (this.$route.path === "/msp/customer/site" && this.$route.query.searchkey === btoaStr) {
            this.$message({
              message: "当前位置就是目标位置",
              type: "info",
              offset: 90,
            });
          } else {
            this.$router.push({ path: "/msp/customer/site", query: { searchkey: btoaStr } });
          }
          this.deleteMsg();
        } else if (btnText === "查看历史") {
          const obj = { isShowLog: true };
          if (this.$route.name === "BOhomePage") {
            this.$message({
              message: "当前位置就是目标位置",
              type: "info",
              offset: 90,
            });
          } else {
            this.$router.push({ name: "BOhomePage", params: { searchkey: JSON.stringify(obj) } });
          }
          this.deleteMsg();
        }
      } else if (sortType === "access-requests") {
        const organizationId = this.itemProps.organizationId;
        const requestId = btn.params.ruuid;
        const deviceId = btn.params.duuid;
        const customerId = btn.params.customerId;
        if (btnText === "接收" || btnText === "接受") {
          const res = await acceptAccessRequestBO(organizationId, requestId);
          if (res === "ACCESS_REQUEST_CAN_NOT_APPROVED") {
            this.$message({
              message: "只能pending才能approved",
              type: "error",
              offset: 90,
            });
          } else {
            this.$message({
              message: res === false ? "接受运维申请失败！" : "接受运维申请成功！",
              type: res === false ? "error" : "success",
              offset: 90,
            });
            if (res !== false) {
              eventBus.$emit("cutomer-assets-refresh");
              this.deleteMsg();
            }
          }
        } else if (btnText === "拒绝") {
          const res = await declineAccessRequestBO(organizationId, requestId);
          this.$message({
            message: res === false ? "拒绝运维申请失败！" : "拒绝运维申请成功！",
            type: res === false ? "error" : "success",
            offset: 90,
          });
          if (res !== false) {
            eventBus.$emit("cutomer-assets-refresh");
            this.deleteMsg();
          }
        }
      } else if (sortType === "invitations") {
        if (btnText === "邀请客户") {
          eventBus.$emit("inviteCustomer", {
            organizationId: btn.params.organization || "",
            title: this.itemProps.title,
          });
        }
      }
    } else {
      const title = this.itemProps.title || "";
      const refuseReg = /^客户.*拒绝运维$/;
      const approveReg = /^客户.*已经授权$/;
      const cancelReg = /^客户.*撤销运维$/;
      // if (title === "解除合作") {
      //   eventBus.$emit("customer-all-refresh");
      //   this.setOrganizations({ curOrg: "", list: [] });
      // } else
      if (refuseReg.test(title) || approveReg.test(title) || cancelReg.test(title)) {
        eventBus.$emit("td-customer-assets-refresh");
      } else if (title === "创建组织") {
        eventBus.$emit("createOrganization");
      }
      if (title !== "创建组织") {
        this.deleteMsg();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-push-item {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  width: 1200px;
  height: 54px;
  // background: rgba(51, 102, 255, 0.1);
  background-color: rgb(234, 239, 255);
  border-radius: 3px;
  position: absolute;
  font-weight: 400;
  font-size: 14px;

  .message-push-title {
    flex: 1;
    margin-left: 13px;
    text-align: left;
    line-height: 22px;
  }

  .message-push-btntext {
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    font-family: "ABBvoice CNSG App Rg";
    line-height: 22px;
    .notAllowed {
      cursor: not-allowed;
      color: #9f9f9f;
      pointer-events: none;
    }

    .fontBtn {
      margin-left: 8px;
    }
  }
}
</style>
