import * as signalR from "@microsoft/signalr";

interface HubData {
  tags: any;
  value: string;
}

class PushService {
  hubConnection: signalR.HubConnection;
  connectionId: string | null = "";
  reportTags = '{ "k": "v" }';
  reportValue = "report value";
  notifications: HubData[] = [];

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(`/pushhub`).withAutomaticReconnect().build();
    this.hubConnection.on("Notify", (tags, value) => {
      console.log("------------------------", tags, value);

      this.notifications.push({ tags, value });
    });
    this.hubConnection.onclose((err) => {
      if (err) console.error(err);
      console.log("connection closed");
      this.connectionId = "";
    });
  }

  doConnect(): void {
    this.hubConnection
      .start()
      .then(() => {
        console.log("Connection started");
        this.connectionId = this.hubConnection.connectionId;
      })
      .catch((err) => console.error("Error while starting connection: " + err));
  }

  doDisconnect(): void {
    this.hubConnection
      .stop()
      .then(() => {
        console.log("Connection stopped");
        this.connectionId = "";
      })
      .catch((err) => console.error("Error while stopping connection: " + err));
  }

  doReport(): void {
    this.hubConnection
      .invoke("Report", JSON.parse(this.reportTags), this.reportValue)
      .catch((err) => console.error(err.toString()));
  }

  deleteMsg(title: string): void {
    //去除notifications的内容;
    const temNotificationList = this.notifications.map((nItem: any) => {
      return JSON.parse(nItem.value);
    });
    // 获取title对应的item的index
    const targetIndex = temNotificationList.findIndex((nItem: any) => {
      return nItem.payload.title === title;
    });
    if (targetIndex !== -1) {
      this.notifications.splice(targetIndex, 1);
    }
  }
}

export default PushService;
