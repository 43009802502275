var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "message-push-item" },
    [
      _c("iconpark-icon", { attrs: { name: "info-circle", color: "#3366FF" } }),
      _c("span", { staticClass: "message-push-title" }, [
        _vm._v(_vm._s(_vm.itemProps.text)),
      ]),
      _c(
        "span",
        { staticClass: "message-push-btntext" },
        [
          _vm._l(_vm.itemProps.btnList, function (btn, index) {
            return _c(
              "span",
              {
                key: index,
                staticClass: "fontBtn",
                on: {
                  click: function ($event) {
                    return _vm.operationFn(btn)
                  },
                },
              },
              [_vm._v(_vm._s(btn.text))]
            )
          }),
          _vm.itemProps.isCanDelete
            ? _c("i", {
                staticClass: "el-icon-close",
                staticStyle: { "margin-left": "22px" },
                on: { click: _vm.deleteMsg },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }