import request from "@/axios/http";
import { number } from "echarts";
import { getDevicesWarningInfos } from "@/api/tdHome";
import { FormItem } from "element-ui";
import base64 from "@/utils/base64";
import { formatDate, formatDateData } from "@/utils/index";

const userInfos = () => {
  return request.get("/api/available-organizations/get", {});
};

const organizationId = "5e29aced-cf49-11ec-be9b-0242ac110003";
// const userid = "d3fb218d-495d-45ed-8137-da2e49972733";
// const uuid = "cf0b9c73-dfe4-11ec-be9b-0242ac110003";
const uuid = "5edb7052-cf49-11ec-be9b-0242ac110003";
// const deviceIdd = "testing20220509";
let customrName: string;
let deviceName: string;
const siteName = "xxx站点";

/*******************【我的客户-客户】******************/
// 获取客户列表
const getCustomers = async (organizationId: string, employeeId: string) => {
  try {
    return await request.get(`/api/organizations/${organizationId}/employees/${employeeId}/customers/get`, {});
  } catch (error) {
    return [];
  }
};

// 解除客户关联绑定
const unassignCustomerBind = async (organizationId: string, customerId: string, employeeId: string) => {
  try {
    return await request.del(
      `/api/organizations/${organizationId}/employees/${employeeId}/unassign/${customerId}/delete`,
      {}
    );
  } catch (error) {
    return false;
  }
};

// 删除客户
const deleteCustomer = async (organizationId: string, customerId: string) => {
  try {
    return await request.del(`/api/organizations/${organizationId}/customers/${customerId}/delete`, {});
  } catch (error) {
    return false;
  }
};

// 根据cutomerId获取分配工程师
const getEmplyeesByCustomer = async (organizationId: string, customerId: string) => {
  try {
    const list = await request.get(`/api/organizations/${organizationId}/customers/${customerId}/employees/get`, {});
    const tempList: { [key: string]: any }[] = [];
    list.forEach((item: { [key: string]: any }) => {
      if (item.role.includes("organization_owner")) {
        tempList.unshift(item);
      } else {
        tempList.push(item);
      }
    });
    return tempList;
  } catch (error) {
    return [];
  }
};

// 删除指定customer下的工程师
const removeEngineerFromCustomer = async (organizationId: string, employeeId: string, customerId: string) => {
  try {
    const result = await request.del(
      `/api/organizations/${organizationId}/employees/${employeeId}/unassign/${customerId}/delete`,
      {}
    );
    return result;
  } catch (error) {
    return false;
  }
};

// 根据cutomerId获取站点数量
const getSitesNumberByCustomer = (organizationId: string, customerId: string) => {
  return request.get(`/api/organizations/${organizationId}/customers/${customerId}/sites/number/get`, {});
};

// 根据cutomerId获取设备列表
const getDevicesByCustomer = async (customerId: string) => {
  try {
    const list = await request.get(`/api/customers/${customerId}/devices/get`, {});
    return list;
  } catch (error) {
    return [];
  }
};

// 根据customerId获取工程师名称
const getEngineersNameByCustomer = async (organizationId: string, customerId: string) => {
  const list: { [key: string]: any }[] = (await getEmplyeesByCustomer(organizationId, customerId)) || [];
  const nameList = [];
  for (const item of list) {
    nameList.push(item.aliasName || item.name);
  }
  return nameList;
};

// 根据customerId获取设备状态列表
const getDevicesStatusByCustomer = async (customerId: string) => {
  const list = await getDevicesByCustomer(customerId);
  const devicesId = [];
  const devices = [];
  const normalList: string[] = [];
  const abnormalList: string[] = [];
  const offlineList: string[] = [];
  const havewarningDeviceIds: string[] = [];
  const deviceIdList = list.map((item: { [key: string]: any }) => {
    return item.deviceId;
  });
  const warningList = await getDevicesWarningInfos(deviceIdList);
  for (const key in list) {
    const item = list[key];
    devices.push({
      deviceId: item.deviceId,
      deviceName: item.deviceName,
      macAddress: item.macAddress,
    });
    const deviceWarning: any = warningList.find((deviceItem: { deviceId: string; count: number }) => {
      return deviceItem.deviceId === item.deviceId;
    });
    const warningMsgCount = deviceWarning?.count || 0;
    devicesId.push(item.deviceId);
    const infos = await getDeviceLicense(item.deviceId);
    // if (infos.license.validFrom === "") {
    //   abnormalList.push(key);
    // } else if (item.connectionState === "Connected") {
    //   normalList.push(key);
    // } else if (item.connectionState === "Disconnected") {
    //   offlineList.push(key);
    // }
    if (item.connectionState === "Connected" && infos.license.validFrom && warningMsgCount === 0) {
      normalList.push(key);
    } else if (item.connectionState === "Disconnected") {
      offlineList.push(key);
    } else {
      abnormalList.push(key);
    }
  }
  return {
    total: list.length || 0,
    normal: normalList.length,
    abnormal: abnormalList.length || 0,
    offline: offlineList.length || 0,
    devices: devicesId,
    deviceList: devices,
  };
};

// 定义返回客户列表表格数据格式
interface customerInfos {
  id: string;
  name: string;
  aliasName: any;
  email: string;
  engineers: string[];
  status: { normal: number; abnormal: number; offline: number };
  deviceIds: string[];
  deviceList: any[];
  inviteStatus: string;
  assets: { site: number; device: number };
}
let lists: customerInfos[];

// 获取客户列表表格数据
const getCustomersListAll = async (organizationId: string, employeeId: string) => {
  try {
    const customers = (await getCustomers(organizationId, employeeId)) || [];
    const customerIds: { uuid: string; userId: string }[] = [];
    lists = [];
    customers.forEach((cItem: any) => {
      customerIds.push({ uuid: cItem.uuid, userId: cItem.userId });
      lists.push({
        id: cItem.uuid,
        name: cItem.name,
        aliasName: cItem.aliasName,
        email: cItem.email,
        engineers: [],
        status: { normal: 0, abnormal: 0, offline: 0 },
        inviteStatus: cItem.status,
        assets: { site: 0, device: 0 },
        deviceIds: [],
        deviceList: [],
      });
    });
    for (const index in customerIds) {
      const ids = customerIds[index];
      let names: string[] = [];
      names = await getEngineersNameByCustomer(organizationId, ids.uuid);
      const sitesNumber = await getSitesNumberByCustomer(organizationId, ids.uuid);
      const assetObj = await getDevicesStatusByCustomer(ids.userId);
      lists[index]["assets"] = {
        site: sitesNumber?.totalSite || 0,
        device: assetObj.total,
      };
      lists[index]["status"] = {
        normal: assetObj?.normal || 0,
        abnormal: assetObj?.abnormal || 0,
        offline: assetObj?.offline || 0,
      };
      lists[index]["engineers"] = names;
      lists[index]["deviceIds"] = assetObj.devices;
      lists[index]["deviceList"] = assetObj.deviceList;
    }
    return lists;
  } catch (e) {
    return [];
  }

  // return lists;
};

/***************************【我的客户-邀请】****************************/
// 获取邀请客户
const getInvitationCustomers = (organizationId: string, params: { [key: string]: any }) => {
  return request.get(`/api/organizations/${organizationId}/invitations/get`, params);
};

// 邀请客户
const invitateCustomer = async (organizationId: string, email: string) => {
  try {
    const result = await request.post(`/api/organizations/${organizationId}/invitations/post`, {
      email,
      invitationType: "ORGANIZATION_TO_CUSTOMER",
    });
    return result;
  } catch (error) {
    return false;
  }
};

// 删除邀请客户
const deleteInvitation = async (organizationId: string, invitationId: string) => {
  try {
    const result = await request.del(`/api/organizations/${organizationId}/invitations/${invitationId}/delete`, {});
    return result;
  } catch (error) {
    return false;
  }
};

interface invitation {
  id: string;
  email: string;
  status: string;
  endTime: string;
}

// 格式化时间
const formatTime = (time: string): string => {
  const dateObj = new Date(time);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
};

// 获取邀请列表所有数据
const getInvitationCustomersAll = async (organizationId: string, params: { [key: string]: any }) => {
  let invitationsData: { list: [] };
  let list = [];
  const formatList: invitation[] = [];
  try {
    invitationsData = await getInvitationCustomers(organizationId, params);
    list = invitationsData?.list;
    for (const key in list) {
      const item: any = list[key];
      formatList.push({
        id: item.uuid,
        email: item.email,
        status: item.status,
        endTime: item.expirationTime ? formatTime(item.expirationTime) : "-",
      });
    }
    return formatList;
  } catch (error) {
    return [];
  }
};

/*************************【站点】*****************************/
// 获取客户详情
const getCustomerDeteailByCustomerId = async (organizationId: string, customerId: string) => {
  try {
    return await request.get(`/api/organizations/${organizationId}/customers/${customerId}/get`, {});
  } catch (error: any) {
    if (error.response.status === 409 && error.response.data === "CUSTOMER_NOT_IN_ORGANIZATION") {
      return error.response.data;
    } else {
      return false;
    }
  }
};

// 设备申请运维
const applyMaintenanceByDevice = async (organizationId: string, deviceId: string, paramsJSON: string) => {
  const params = JSON.parse(paramsJSON);
  try {
    return await request.post(`/api/organizations/${organizationId}/devices/${deviceId}/access-request/post`, params);
  } catch (error: any) {
    if (error.response.status === 409 && error.response.data === "DEVICE_HAS_EXIST_ACCEPT_REQUEST") {
      return error.response.data;
    } else {
      return false;
    }
  }
};

// 站点申请运维
const applyMaintenanceBySite = async (
  organizationId: string,
  customerId: string,
  siteId: string,
  paramsJSON: string
) => {
  const params = JSON.parse(paramsJSON);
  try {
    return await request.post(
      `/api/organizations/${organizationId}/customers/${customerId}/sites/${siteId}/access-request/post`,
      params
    );
  } catch (error: any) {
    if (error.response.status === 409 && error.response.data === "DEVICE_HAS_EXIST_ACCEPT_REQUEST") {
      return error.response.data;
    } else {
      return false;
    }
  }
};

// 撤回申请运维
const revokeMaintApplication = async (organizationId: string, requestId: string) => {
  try {
    return await request.post(`/api/organizations/${organizationId}/access-requests/${requestId}/revoke/post`, {});
  } catch (error) {
    return false;
  }
};

// 修改运维
const modifyMaintenanceByRequestId = async (organizationId: string, requestId: string, paramsJSON: string) => {
  const params = JSON.parse(paramsJSON);
  try {
    return await request.put(`/api/organizations/${organizationId}/access-requests/${requestId}/put`, params);
  } catch (error) {
    return false;
  }
};

// 删除子站点
const deleteSubSite = async (siteId: string, subSiteId: string) => {
  try {
    return await request.del(`/api/organizations/customers/sites/${siteId}/subsites/${subSiteId}/delete`, {});
  } catch (error) {
    return false;
  }
};

// 获取当前组织下的所有工程师
const getAllEmplyees = async (organizationId: string) => {
  try {
    const data = await request.get(`/api/organizations/${organizationId}/employees/get`, {
      pageSize: 100,
      pageNumber: 1,
    });
    const list: { [key: string]: string }[] = [];
    data.list.forEach((item: { [key: string]: string }) => {
      const targetEmployee = {
        employeeId: item.uuid,
        name: item.name,
        aliasName: item.aliasName,
        email: item.email,
        role: item.role,
        userId: item.userId,
      };
      if (targetEmployee.role.includes("organization_owner")) {
        list.unshift(targetEmployee);
      } else {
        list.push(targetEmployee);
      }
    });
    return list;
  } catch (error) {
    return [];
  }
};

// 给指定客户分配工程师
const assignEmployeeToCustomer = async (organizationId: string, employeeId: string, customerId: string) => {
  try {
    return await request.post(
      `/api/organizations/${organizationId}/employees/${employeeId}/assign/customers/${customerId}/post`,
      {}
    );
  } catch (error) {
    return false;
  }
};

// 新建站点
const newBuildSite = async (organizationId: string, customerId: string, params: string) => {
  try {
    const siteParams = JSON.parse(params);
    return await request.post(`/api/organizations/${organizationId}/customers/${customerId}/site/post`, siteParams);
  } catch (error) {
    return false;
  }
};

// 编辑站点
const editSite = async (siteId: string, params: { [key: string]: any }) => {
  try {
    return await request.put(`/api/organizations/customers/sites/${siteId}/put`, params);
  } catch (error) {
    return false;
  }
};

// 待绑定的设备列表
const getDevicesToBind = async (organizationId: string, customerId: string) => {
  try {
    const list = await getDevicesNotUnderSiteByCustomerId(organizationId, customerId);
    const tempList: { [key: string]: any }[] = [];
    list.forEach((item: { [key: string]: any }) => {
      tempList.push({
        deviceId: item.deviceId,
        macAddress: item.serialNumber, //待修改（暂时没有mac）
        serialNumber: item.serialNumber,
        deviceName: item.deviceName,
        status: item.connectionState,
        disabled: false,
      });
    });
    return tempList;
  } catch (error) {
    return [];
  }
};

// 获取工程师信息
const getEmplyeeInfos = async (organizationId: string, employeeId: string) => {
  try {
    const infos = await request.get(`/api/organizations/${organizationId}/employees/${employeeId}/get`, {});
    return {
      id: infos.uuid,
      name: infos.name,
      aliasName: infos.aliasName || "",
      email: infos.email,
      role: infos.role,
      status: infos.status,
      organizationId: infos.organizationId,
    };
  } catch (error) {
    return {
      id: "",
      name: "",
      aliasName: "",
      email: "",
      role: "",
      status: "",
      organizationId: "",
    };
  }
};

// 获取运维历史
const getHistoryByCustomerId = async (organizationId: string, customerId: string, pageNumber: number) => {
  try {
    const histoyObj = await request.get(`/api/organizations/${organizationId}/customers/${customerId}/sessions/get`, {
      pageNumber,
    });
    const list = [];
    if (histoyObj) {
      for (const item of histoyObj.list || []) {
        const timeObj = formatDateData(item.createTime, item.closedTime || "");
        const employeeInfos = await getEmplyeeInfos(organizationId, item.employeeId);
        const deviceInfos = await getDeviceInfos(item.deviceId);
        list.push({
          sessionId: item.uuid,
          siteName: item.site || "-",
          device: deviceInfos.name,
          from: timeObj.createTime,
          to: timeObj.endTime,
          usedTime: timeObj.duration,
          operator: employeeInfos.aliasName || employeeInfos.name || "-",
          remark: item.note || "-",
          collapse: false,
        });
      }
    }
    return {
      total: histoyObj.count,
      currentPage: histoyObj.currentPage,
      pageSize: histoyObj.pageSize,
      pageTotal: histoyObj.pageTotal,
      list: list,
    };
  } catch (error) {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      list: [],
    };
  }
};

// 编辑运维历史
const editSession = async (sessionId: string, remark: string) => {
  try {
    return await request.put(`/api/sessions/${sessionId}/put`, { note: remark });
  } catch (error) {
    return false;
  }
};

// 获取站点下的设备
const getDevicesUnderSiteByCustomerId = (organizationId: string, customerId: string, place: string) => {
  if (place === "TD") {
    return request.get(`/api/organizations/${organizationId}/customers/${customerId}/sites/devices/get`, {});
  } else if (place === "BO") {
    return request.get(`/api/organizations/${organizationId}/customer/sites/devices/get`, {});
  }
};

// 获取非站点下的设备
const getDevicesNotUnderSiteByCustomerId = (organizationId: string, customerId: string, place = "TD") => {
  if (place === "TD") {
    return request.get(`/api/organizations/${organizationId}/customers/${customerId}/notinsite/devices/get`, {});
  } else if (place === "BO") {
    return request.get(`/api/organizations/${organizationId}/customer/notinsite/devices/get`, {});
  }
};

// 获取设备license
const getDeviceLicense = async (deviceId: string) => {
  if (deviceId) {
    try {
      const infos = await request.get(`/api/device/${deviceId}/license/get`, {});
      return {
        license: {
          validFrom: infos?.license?.validFrom || "",
          validTo: infos?.license?.validTo || "",
        },
      };
    } catch (error) {
      return {
        license: {
          validFrom: "",
          validTo: "",
        },
      };
    }
  } else {
    return {
      license: {
        validFrom: "",
        validTo: "",
      },
    };
  }
};

// 批量获取设备license
const getDeviceLicenseBatch = async (deviceIds: string[]) => {
  if (deviceIds) {
    try {
      const list = (await request.post(`/api/license/query/post`, deviceIds)) || [];
      const licenseList = [];
      for (const item of list) {
        licenseList.push({
          deviceId: item.device.deviceId,
          license: {
            validFrom: item?.license?.validFrom || "",
            validTo: item?.license?.validTo || "",
          },
        });
      }
      return licenseList;
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
};

// 获取站点运维信息
const getMaintenanceInfos = async (organizationId: string, customerId: string, siteId: string, place: string) => {
  if (place === "TD") {
    try {
      return await request.get(
        `/api/organizations/${organizationId}/customer/${customerId}/sites/${siteId}/access-request/get`,
        {}
      );
    } catch (error) {
      return {};
    }
  } else {
    try {
      return await request.get(`/api/organizations/${organizationId}/customer/sites/${siteId}/access-request/get`, {});
    } catch (error) {
      return {};
    }
  }
};

// 获取非站点下的设备运维信息
const getMaintenanceNotInSiteInfos = async (
  organizationId: string,
  customerId: string,
  deviceId: string,
  place: string
) => {
  if (place === "TD") {
    try {
      const infos = await request.get(
        `/api/organizations/${organizationId}/customer/${customerId}/devices/${deviceId}/access-request/get`,
        {}
      );
      return {
        id: infos?.uuid || "",
        status: infos?.status || "",
        createTime: infos?.createTime || "",
        startTime: infos?.startTime || "",
        endTime: infos.endTime || "",
        note: infos.note || "",
        activeSessionDtoList: infos.activeSessionDtoList || [],
        permanent: infos.permanent || false,
      };
    } catch (error) {
      return {
        id: "",
        status: "",
        createTime: "",
        startTime: "",
        endTime: "",
        note: "",
        activeSessionDtoList: [],
        permanent: false,
      };
    }
  } else {
    try {
      const infos = await request.get(
        `/api/organizations/${organizationId}/customer/devices/${deviceId}/access-request/get`,
        {}
      );
      return {
        id: infos?.uuid || "",
        status: infos?.status || "",
        createTime: infos?.createTime || "",
        startTime: infos?.startTime || "",
        endTime: infos.endTime || "",
        note: infos.note || "",
        activeSessionDtoList: infos.activeSessionDtoList || [],
        permanent: infos.permanent || false,
      };
    } catch (error) {
      return {
        id: "",
        status: "",
        createTime: "",
        startTime: "",
        endTime: "",
        note: "",
        activeSessionDtoList: [],
        permanent: false,
      };
    }
  }
};

interface customerInfo {
  name: string;
  aliasName: any;
  email: string;
  address: string;
  remark: string;
  engineers: any[];
  history: { [key: string]: any };
}
const getCustomerDeteailsAll = async (organizationId: string, customerId: string) => {
  try {
    const infosObj: any = await getCustomerDeteailByCustomerId(organizationId, customerId);
    customrName = infosObj.name;
    const details: customerInfo = {
      name: infosObj.name,
      aliasName: infosObj.aliasName,
      email: infosObj.email,
      address: infosObj.address || "-",
      remark: infosObj.remark || "-",
      engineers: (await getEmplyeesByCustomer(organizationId, customerId)) || [],
      history: await getHistoryByCustomerId(organizationId, customerId, 1),
    };
    return details;
  } catch (error) {
    return false;
  }
};

// 获取消息
const getMessages = async (organizationId: string) => {
  try {
    return await request.get(`/api/message/list/get`, { type: "notification", organizationId, key: "userId" });
  } catch (error) {
    return [];
  }
};

// 判断当前时间是否在时间段内
const isWithinPeriod = ([startTime, endTime]: any[]) => {
  if (startTime && endTime) {
    const nowTime = new Date().getTime();
    const beforeTime = new Date(startTime).getTime();
    const afterTime = new Date(endTime).getTime();
    if (nowTime > beforeTime && nowTime < afterTime) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const formatDevicesUnderSite = async (list: [], warinigList: [], place: string) => {
  const sitesArr = [];
  let singleSiteIdArr: string[] = [];
  const tableData = [];
  for (const index in list) {
    const item: { siteId: string } = list[index];
    sitesArr.push(item.siteId);
    const siteSet = new Set(sitesArr);
    singleSiteIdArr = Array.from(siteSet);
  }
  for (const id of singleSiteIdArr) {
    const tempData = list.filter((item: { [key: string]: any }) => {
      return item.siteId === id;
    });
    const haveDeviceSubSiteList = tempData.filter((item: { [key: string]: any }) => {
      return item.deviceId;
    });
    const deviceIdInSiteList = haveDeviceSubSiteList.map((item: { [key: string]: any }) => {
      return item.deviceId;
    });
    const warningList = await getDevicesWarningInfos(deviceIdInSiteList);
    const siteMaintenanceInfos = await getMaintenanceInfos(
      tempData[0]["organizationId"],
      tempData[0]["customerId"],
      tempData[0]["siteId"],
      place
    );
    const activeSessionDtoList = siteMaintenanceInfos.activeSessionDtoList || [];
    const childrens = [];
    let normalCount = 0;
    let abnormalCount = 0;
    let offlineCount = 0;
    let validCount = 0;
    let unactivateCount = 0;
    let expiredCount = 0;
    let deviceNumber = 0;
    let haveLicenseCount = 0;
    let siteTotalWarning = 0;
    for (const key in tempData) {
      const item: { [key: string]: string } = tempData[key];
      const chooseKey = [];
      const deviceWarning: any = warningList.find((deviceItem: { deviceId: string; count: number }) => {
        return deviceItem.deviceId === item.deviceId;
      });
      const warningMsgCount = deviceWarning?.count || 0;
      if (warningMsgCount) {
        chooseKey.push("warning");
      }
      siteTotalWarning = siteTotalWarning + warningMsgCount;
      const licenseData = await getDeviceLicense(item.deviceId);
      const deviceInfos = await getDeviceInfos(item.deviceId);
      const startTime = licenseData.license.validFrom;
      const endTime = licenseData.license.validTo;
      let time = "";
      if (item.deviceId) {
        deviceNumber = deviceNumber + 1;
        // const haveWarningList = warinigList.filter((warningItem: { [key: string]: any }) => {
        //   const operations = warningItem?.payload?.operation || [];
        //   let deviceId = "";
        //   operations.forEach((operationItem: { [key: string]: any }) => {
        //     if (operationItem.deviceId) {
        //       deviceId = operationItem.deviceId;
        //     }
        //   });
        //   return item.deviceId === deviceId;
        // });
        if (deviceInfos.status === "Connected" && startTime && warningMsgCount === 0) {
          normalCount = normalCount + 1;
          chooseKey.push("online");
        } else if (deviceInfos.status === "Disconnected") {
          chooseKey.push("offline");
          offlineCount = offlineCount + 1;
          // } else if (startTime === "" || haveWarningList.length) {
          //   chooseKey.push("abnormal");
          //   abnormalCount = abnormalCount + 1;
          // }
        } else {
          chooseKey.push("abnormal");
          abnormalCount = abnormalCount + 1;
        }
      }
      if (startTime) {
        haveLicenseCount = haveLicenseCount + 1;
        if (endTime) {
          if (new Date(endTime) > new Date()) {
            time = `${new Date(startTime).getFullYear()}-${(new Date(startTime).getMonth() + 1 + "").padStart(
              2,
              "0"
            )} 至 ${new Date(endTime).getFullYear()}-${(new Date(endTime).getMonth() + 1 + "").padStart(2, "0")}`;
            chooseKey.push("activated");
            validCount = validCount + 1;
          } else {
            time = "已过期";
            chooseKey.push("expired");
            expiredCount = expiredCount + 1;
          }
        }
      } else {
        if (item.deviceId) {
          time = "未激活";
          chooseKey.push("unactivated");
          unactivateCount = unactivateCount + 1;
        }
      }
      const deviceSessionList = activeSessionDtoList.filter((sessionItem: { [key: string]: any }) => {
        return item.deviceId === sessionItem.deviceId;
      });

      childrens.push({
        index: item.subSiteName + "_" + id + "_" + key,
        siteId: item.uuid || "",
        site: item.subSiteName,
        parentSite: item.siteName,
        parentSiteId: item.siteId,
        deviceId: item.deviceId || "",
        deviceName: deviceInfos.name || "",
        deviceType: deviceInfos.type || "",
        deviceMac: deviceInfos.macAddress,
        equipmentInfo: {
          name: deviceInfos.name,
          code: deviceInfos.serialNumber,
        },
        permission: {
          time: time,
        },
        warningInfo: warningMsgCount,
        customer: "",
        status: deviceInfos.status,
        miantenanceStatus: siteMaintenanceInfos?.status || "",
        level: 2,
        duration: [siteMaintenanceInfos?.startTime || "", siteMaintenanceInfos?.endTime || ""],
        chooseKey: chooseKey,
        detail: {
          // period: deviceSessionList.length ? formatDate(deviceSessionList[0].created) + "-" : "-",
          period: "-",
          remark: "-",
          isPermanent: siteMaintenanceInfos.permanent,
          datePeriod: [deviceSessionList.length ? deviceSessionList[0].created : "", ""],
          isWithinPeriod: isWithinPeriod([siteMaintenanceInfos?.startTime || "", siteMaintenanceInfos?.endTime || ""]),
        },
        isMaintenancing: deviceSessionList.length ? true : false,
        parentIsMaintenancing: activeSessionDtoList.length ? true : false,
        maintenancingEmployees: deviceSessionList.map((item: { [key: string]: any }) => {
          return { name: item.employee.name, userId: item.employee.userId };
        }),
        activeSessionDtoData: deviceSessionList[0],
      });
    }

    tableData.push({
      index: tempData[0]["siteName"] + "_" + id,
      siteId: tempData[0]["siteId"] || "",
      site: tempData[0]["siteName"],
      equipmentInfo: {
        normal: normalCount,
        abnormal: abnormalCount,
        offline: offlineCount,
        total: normalCount + abnormalCount + offlineCount,
      },
      permission: {
        valid: validCount,
        unactivated: unactivateCount,
      },
      // warningInfo: deviceNumber - haveLicenseCount,
      warningInfo: siteTotalWarning,
      status: siteMaintenanceInfos?.status || "",
      miantenanceStatus: siteMaintenanceInfos?.status || "",
      requestId: siteMaintenanceInfos?.uuid || "", //运维id
      level: 1,
      duration: [siteMaintenanceInfos?.startTime || "", siteMaintenanceInfos?.endTime || ""],
      isMaintenancing: activeSessionDtoList.length ? true : false,
      maintenancingEmployees: activeSessionDtoList.map((item: { [key: string]: any }) => {
        return { name: item.employee.name, userId: item.employee.userId };
      }),
      detail: {
        period:
          siteMaintenanceInfos?.startTime && siteMaintenanceInfos?.endTime
            ? `${formatDate(siteMaintenanceInfos?.startTime)} - ${formatDate(siteMaintenanceInfos?.endTime)}`
            : "-",
        remark: siteMaintenanceInfos?.note || "-",
        isPermanent: siteMaintenanceInfos.permanent,
        isWithinPeriod: isWithinPeriod([siteMaintenanceInfos?.startTime || "", siteMaintenanceInfos?.endTime || ""]),
        datePeriod: [siteMaintenanceInfos?.startTime || "", siteMaintenanceInfos?.endTime || ""],
      },
      realDeviceCount: deviceNumber,
      children: childrens,
    });
  }
  return tableData;
};

const formatDevicesNotInSite = async (
  list: { [key: string]: any }[],
  organizationsId: string,
  customerId: string,
  warinigList: [],
  place: string
) => {
  const tableData: { [key: string]: any }[] = [];
  try {
    const deviceIdList = list.map((item: { [key: string]: any }) => {
      return item.deviceId;
    });
    const warningList = await getDevicesWarningInfos(deviceIdList);
    for (const item of list) {
      const chooseKey = [];
      const deviceWarning: any = warningList.find((deviceItem: { deviceId: string; count: number }) => {
        return deviceItem.deviceId === item.deviceId;
      });
      const warningMsgCount = deviceWarning?.count || 0;
      if (warningMsgCount) {
        chooseKey.push("warning");
      }
      const licenseData = await getDeviceLicense(item.deviceId);
      const deviceInfos = await getDeviceInfos(item.deviceId);
      const infos = await getMaintenanceNotInSiteInfos(organizationsId, customerId, item.deviceId, place);
      const activeSessionDtoList = infos.activeSessionDtoList || [];
      const startTime = licenseData.license.validFrom;
      const endTime = licenseData.license.validTo;
      let time = "";
      // const haveWarningList = warinigList.filter((warningItem: { [key: string]: any }) => {
      //   const operations = warningItem?.payload?.operation || [];
      //   let deviceId = "";
      //   operations.forEach((operationItem: { [key: string]: any }) => {
      //     if (operationItem.deviceId) {
      //       deviceId = operationItem.deviceId;
      //     }
      //   });
      //   return item.deviceId === deviceId;
      // });
      if (deviceInfos.status === "Connected" && startTime && warningMsgCount === 0) {
        chooseKey.push("online");
      } else if (deviceInfos.status === "Disconnected") {
        chooseKey.push("offline");
        // } else if (startTime === "" || haveWarningList.length) {
      } else if (startTime === "" || warningMsgCount) {
        chooseKey.push("abnormal");
      }
      if (startTime) {
        if (endTime) {
          if (new Date(endTime) > new Date()) {
            chooseKey.push("activated");
            time = `${new Date(startTime).getFullYear()}-${(new Date(startTime).getMonth() + 1 + "").padStart(
              2,
              "0"
            )} 至 ${new Date(endTime).getFullYear()}-${(new Date(endTime).getMonth() + 1 + "").padStart(2, "0")}`;
          } else {
            chooseKey.push("expired");
            time = "已过期";
          }
        }
      } else {
        chooseKey.push("unactivated");
        time = "未激活";
      }
      tableData.push({
        index: item.deviceName || "-",
        siteId: "",
        site: "",
        deviceId: item.deviceId || "",
        deviceName: item.deviceName || "",
        deviceType: deviceInfos.type || "",
        deviceMac: deviceInfos.macAddress,
        equipmentInfo: {
          name: item.deviceName || "-",
          code: item.serialNumber || "-",
        },
        permission: {
          time: time,
        },
        warningInfo: warningMsgCount,
        chooseKey: chooseKey,
        status: deviceInfos.status,
        miantenanceStatus: infos.status,
        activeSessionDtoData: infos.activeSessionDtoList[0],
        requestId: infos.id,
        duration: [infos?.startTime || "", infos?.endTime || ""],
        isMaintenancing: activeSessionDtoList.length ? true : false,
        maintenancingEmployees: activeSessionDtoList.map((item: { [key: string]: any }) => {
          return { name: item.employee.name, userId: item.employee.userId };
        }),
        detail: {
          period:
            infos?.startTime && infos?.endTime
              ? `${formatDate(infos?.startTime)} - ${formatDate(infos?.endTime)}`
              : "-",
          remark: infos.note || "-",
          isPermanent: infos.permanent,
          isWithinPeriod: isWithinPeriod([infos?.startTime || "", infos?.endTime || ""]),
          datePeriod: [infos?.startTime || "", infos?.endTime || ""],
        },
      });
    }
    return tableData;
  } catch (error) {
    return tableData;
  }
};

const getDevicesAll = async (organizationId: string, customerId: string, place = "TD") => {
  //place --在什么环境里调用   TD || BO
  try {
    const devicesInSite = await getDevicesUnderSiteByCustomerId(organizationId, customerId, place);
    const devicesNotInSite = (await getDevicesNotUnderSiteByCustomerId(organizationId, customerId, place)) || [];
    const warinigList = await getMessages(organizationId);
    const list1 = await formatDevicesUnderSite(devicesInSite, warinigList, place);
    const list2 = await formatDevicesNotInSite(devicesNotInSite, organizationId, customerId, warinigList, place);
    return [...list1, ...list2];
  } catch (error) {
    return [];
  }
};

/**************************【设备】*********************************/
// 根据deviceId获取设备详情
const getDeviceInfos = async (deviceId: string) => {
  if (deviceId) {
    try {
      const infos = await request.get(`/api/devices/${deviceId}/get`, {});
      return {
        id: infos.deviceId,
        name: infos.deviceName,
        status: infos.connectionState,
        site: infos.siteName || "",
        customer: customrName,
        type: infos.type || "",
        macAddress: infos.macAddress || "",
        serialNumber: infos.serialNumber || "",
      };
    } catch (error) {
      return {
        id: "",
        name: "",
        status: "",
        site: "",
        customer: "",
        type: "",
        macAddress: "",
        serialNumber: "",
      };
    }
  } else {
    return {
      id: "",
      name: "",
      status: "",
      site: "",
      customer: "",
      macAddress: "",
      serialNumber: "",
    };
  }
};

//告警监控
const getPhysicalAddress = async (organizationId: string, deviceId: string) => {
  try {
    const list = await request.get(`/api/organizations/${organizationId}/alert/physics/address/get`, {
      deviceId: deviceId,
    });
    const tableData: { alertId: any; address: string; rules: string; remark: string }[] = [];
    if (list) {
      for (const item of list) {
        tableData.push({
          alertId: item.physicalAddressAlertId,
          address: item.physicalAddress,
          rules: "失去响应后报警",
          remark: item.alertMessage,
        });
      }
      return tableData;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const addPhysicalAddress = async (organizationId: string, params: { [key: string]: any }) => {
  try {
    return await request.post(`/api/organizations/${organizationId}/alert/physics/address/post`, params);
  } catch (error) {
    return false;
  }
};

const editPhysicalAddress = async (organizationId: string, params: { [key: string]: any }) => {
  try {
    return await request.put(`/api/organizations/${organizationId}/alert/physics/address/put`, params);
  } catch (error) {
    return false;
  }
};

const deletePhysicalAddress = async (organizationId: string, physicalAddressAlertId: string) => {
  try {
    return await request.del(
      `/api/organizations/${organizationId}/alert/physics/address/${physicalAddressAlertId}/delete`,
      {}
    );
  } catch (error) {
    return false;
  }
};

const getGroupAddress = async (organizationId: string, deviceId: string) => {
  try {
    const list = await request.get(`/api/organizations/${organizationId}/alert/groups/address/get`, {
      deviceId: deviceId,
    });
    const tableData: { alertId: any; address: string; rules: string; remark: string }[] = [];
    if (list) {
      for (const item of list) {
        tableData.push({
          alertId: item.groupAddressAlertId,
          address: item.groupAddress,
          rules: "失去响应后报警",
          remark: item.alertMessage,
        });
      }
      return tableData;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const addGroupAddress = async (organizationId: string, params: { [key: string]: any }) => {
  try {
    return await request.post(`/api/organizations/${organizationId}/alert/groups/address/post`, params);
  } catch (error) {
    return false;
  }
};

const editGroupAddress = async (organizationId: string, params: { [key: string]: any }) => {
  try {
    return await request.put(`/api/organizations/${organizationId}/alert/groups/address/put`, params);
  } catch (error) {
    return false;
  }
};

const deleteGroupAddress = async (organizationId: string, groupAddressAlertId: string) => {
  try {
    return await request.del(
      `/api/organizations/${organizationId}/alert/groups/address/${groupAddressAlertId}/delete`,
      {}
    );
  } catch (error) {
    return false;
  }
};

// 获取【设备-固态升级-升级列表】
const getUpgradeList = async (organizationId: string, deviceId: string) => {
  try {
    let list = [];
    if (organizationId) {
      list = await request.get(`/api/organizations/${organizationId}/devices/${deviceId}/categories/get`, {});
    }
    const formatList = [];
    for (const item of list) {
      const desc = item.description ? base64.decode(item.description) : "";
      formatList.push({
        name: item.category,
        nowVersion: item.curVersion,
        newestVersion: item.targetVersion,
        state: item.state || "needConfirm",
        isNewest: item.curVersion === item.targetVersion,
        targetTime: item.targetTime,
        action: item.action || "",
        description: item.desc,
      });
    }
    return formatList;
  } catch (error) {
    return [];
  }
};

// 编辑升级列表
const operateUpgradeItem = async (
  organizationId: string,
  deviceId: string,
  params: { category: string; action: string; targetTime: string }
) => {
  try {
    return await request.put(`/api/organizations/${organizationId}/devices/${deviceId}/categories/put`, params);
  } catch (error) {
    return false;
  }
};

// 获取【设备-固态升级-升级记录】
const getUpgradeLogs = async (organizationId: string, deviceId: string) => {
  try {
    let logsList = [];
    if (organizationId) {
      logsList = await request.get(`/api/organizations/${organizationId}/devices/${deviceId}/categories/logs/get`, {});
    }
    const formatList = [];
    for (const item of logsList) {
      formatList.push({
        ...item,
        formatUpdateTime: item.updateTime ? formatDate(item.updateTime) : "-",
      });
    }
    return formatList;
  } catch (error) {
    return [];
  }
};

// 激活设备
const activiateDevice = async (deviceId: string, code: string) => {
  try {
    const info = await request.post(`/api/license/preview/post`, {
      activationCode: code,
      tags: {
        deviceId: deviceId,
      },
    });
    return info || true;
  } catch (error) {
    return false;
  }
  return;
};

// 确认激活设备
const activiateDeviceConfirm = async (vertifyId: string) => {
  try {
    return await request.post(`/api/license/confirm/${vertifyId}/post`, {});
  } catch (error) {
    return false;
  }
};

// 解绑站点设备关联关系
const unassignSiteDevice = async (siteId: string, subSiteId: string, deviceId: string) => {
  try {
    return await request.del(
      `/api/organizations/customers/sites/${siteId}/subsites/${subSiteId}/device/${deviceId}/delete`,
      {}
    );
  } catch (error) {
    return false;
  }
};

// 断开连接pcClient
const interruptlink = async (organizationId: string, sessionId: string, params: object) => {
  try {
    return await request.post(`/api/organizations/${organizationId}/sessions/${sessionId}/close/post`, params);
  } catch (error) {
    return false;
  }
};

// 获取设备详情页中推送邮箱的状态
const getEmailOperationStatus = async (deviceId: string) => {
  try {
    return await request.get(`/api/device/${deviceId}/email/get`, {});
  } catch (error) {
    return false;
  }
};

// 修改设备详情页中推送邮箱的状态
const editEmailOperationStatus = async (deviceId: string, params: any) => {
  try {
    return await request.put(`/api/device/${deviceId}/email/put`, params);
  } catch (error) {
    return false;
  }
};

// 修改客户别名
const modifyCustomerNickName = async (organizationId: string, customerId: string, aliasName: string) => {
  try {
    return await request.put(`/api/organizations/${organizationId}/customers/${customerId}/alias-name/put`, {
      aliasName,
    });
  } catch (error) {
    return false;
  }
};

export {
  getCustomers,
  unassignCustomerBind,
  deleteCustomer,
  getEmplyeesByCustomer,
  userInfos,
  getCustomersListAll,
  getDevicesStatusByCustomer,
  getInvitationCustomersAll,
  invitateCustomer,
  deleteInvitation,
  getCustomerDeteailsAll,
  getDevicesAll,
  getDeviceInfos,
  getMaintenanceNotInSiteInfos,
  getPhysicalAddress,
  addPhysicalAddress,
  editPhysicalAddress,
  deletePhysicalAddress,
  getGroupAddress,
  addGroupAddress,
  editGroupAddress,
  deleteGroupAddress,
  getUpgradeList,
  operateUpgradeItem,
  getUpgradeLogs,
  activiateDevice,
  activiateDeviceConfirm,
  getAllEmplyees,
  assignEmployeeToCustomer,
  removeEngineerFromCustomer,
  newBuildSite,
  editSite,
  deleteSubSite,
  applyMaintenanceByDevice,
  applyMaintenanceBySite,
  revokeMaintApplication,
  modifyMaintenanceByRequestId,
  getDevicesToBind,
  editSession,
  getHistoryByCustomerId,
  unassignSiteDevice,
  getCustomerDeteailByCustomerId,
  getDeviceLicense,
  getDeviceLicenseBatch,
  interruptlink,
  getEmailOperationStatus,
  editEmailOperationStatus,
  modifyCustomerNickName,
};
