import request from "@/axios/http";

const organizationId = "5e29aced-cf49-11ec-be9b-0242ac110003";

// 获取TD组织列表
const availableOrganizations = async () => {
  try {
    const orgList = await request.get(`/api/available-organizations/get`, {});
    orgList.reverse();
    return orgList;
  } catch (error) {
    return [];
  }
};

// 获取BO组织列表
const getBoOrganization = async () => {
  try {
    const res = await request.get(`/api/customer/organization/get`, {});
    res && (res.name = "");
    return [res];
  } catch (error) {
    return [];
  }
};

const createOrganizations = async (params: any, config: any) => {
  try {
    return await request.post(`/api/organizations/post`, params, config);
  } catch (error) {
    return false;
  }
};

/***************************我的资产***************************/

/***************************我的运维***************************/

// 获取组织（公司）信息
const getOrganizationInfos = async (organizationId: string) => {
  try {
    const infos = await request.get(`/api/customer/organization/get`, {});
    const companyInfo: { [key: string]: string } = {
      id: infos.uuid,
      logoUrl: infos.logo,
      name: infos.name,
      email: infos.email,
      mobile: infos.phone,
      city: infos.city,
      postcode: infos.postcode,
      country: infos.country,
      address: infos.address,
      desc: infos.description,
    };
    return companyInfo;
  } catch (error) {
    return {
      id: "",
      logoUrl: "",
      name: "-",
      email: "-",
      mobile: "-",
      city: "-",
      postcode: "-",
      country: "-",
      address: "-",
      desc: "-",
    };
  }
};

// 获取customer信息
const getCustomerInfos = async (organizationId: string) => {
  try {
    const infos = await request.get(`/api/organizations/${organizationId}/customer/get`, {});
    return {
      id: infos.uuid,
      name: infos.name,
    };
  } catch (error) {
    return {
      id: "",
      name: "",
    };
  }
};

// 获取工程师信息
const getEmplyeeInfos = async (organizationId: string, employeeId: string) => {
  try {
    const infos = await request.get(`/api/organizations/${organizationId}/employees/${employeeId}/get`, {});
    return {
      id: infos.uuid,
      name: infos.name,
      aliasName: infos.aliasName || "",
      email: infos.email,
      role: infos.role,
      status: infos.status,
      organizationId: infos.organizationId,
    };
  } catch (error) {
    return {
      id: "",
      name: "",
      aliasName: "",
      email: "",
      role: "",
      status: "",
      organizationId: "",
    };
  }
};

// 获取设备详情
const getDeviceInfos = async (deviceId: string) => {
  try {
    const infos = await request.get(`/api/devices/${deviceId}/get`, {});
    return {
      id: infos.deviceId,
      name: infos.deviceName,
      macAddress: infos.macAddress,
      serialNumber: infos.serialNumber,
      status: infos.status,
      type: infos.type,
      connectionState: infos.connectionState,
    };
  } catch (error) {
    return {
      id: "",
      name: "",
      macAddress: "",
      serialNumber: "",
      status: "",
      type: "",
      connectionState: "",
    };
  }
};

// 格式化时间   xxxx年xx月xx日 xx:xx
const formatDate = (time: string): string => {
  if (time) {
    const dateObj = new Date(time);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const hour = dateObj.getHours() + "";
    const minute = dateObj.getMinutes() + "";
    return `${year}年${month}月${day}日 ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
  } else {
    return "-";
  }
};

const formatDateData = (createTime: string, endTime: string) => {
  if (endTime) {
    const seconds = (new Date(endTime).getTime() - new Date(createTime).getTime()) / 1000;
    const day = Math.floor(seconds / (60 * 60 * 24));
    const hour = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
    const minute = Math.floor((seconds % (60 * 60)) / 60);
    return {
      createTime: formatDate(createTime),
      endTime: formatDate(endTime),
      duration: `${day ? day + "天 " : ""}${hour ? hour + "小时 " : ""}${minute ? minute + "分钟" : ""}` || "0分钟",
    };
  } else {
    return {
      createTime: formatDate(createTime),
      endTime: "-",
      duration: "-",
    };
  }
};

// 获取运维历史
const getSessions = async (organizationId: string, params: object) => {
  try {
    // const sessionsInfos = await request.get(
    //   `/api/organizations/${organizationId}/customers/${customerId}/sessions/get`,
    //   { pageNumber }
    // );
    const sessionsInfos = await request.get(
      `/api/organizations/${organizationId}/customer/session/history/get`,
      params
    );
    const historyList = [];
    for (const item of sessionsInfos.list) {
      const employeeInfos = await getEmplyeeInfos(organizationId, item.employeeId);
      const deviceInfos = await getDeviceInfos(item.deviceId);
      const duration = formatDateData(item.createTime, item.closedTime).duration;
      historyList.push({
        sessionId: item.uuid,
        device: deviceInfos.name,
        from: formatDate(item.createTime),
        to: formatDate(item.closedTime),
        usedTime: duration,
        operator: employeeInfos.aliasName || employeeInfos.name,
        remark: item.note,
        collapse: false,
      });
    }
    return {
      total: sessionsInfos.count,
      currentPage: sessionsInfos.currentPage,
      pageSize: sessionsInfos.pageSize,
      pageTotal: sessionsInfos.pageTotal,
      list: historyList,
    };
  } catch (error) {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      list: [],
    };
  }
};

// 删除运维信息
const deleteSessionById = async (organizationId: string, sessionId: string) => {
  try {
    return await request.del(`/api/organizations/${organizationId}/customer/sessions/${sessionId}/delete`, {});
  } catch (error) {
    return false;
  }
};

// 添加设备
const addDevice = async (serialNumber: string, gatewayName: string) => {
  try {
    return await request.post(`/api/devices/bind/post`, { serialNumber, gatewayName });
  } catch (error: any) {
    if (error.response.status === 409) {
      return error.response.data;
    } else {
      return false;
    }
  }
};

// 获取绑定设备的状态
const getDeviceBindStatus = async (serialNumber: string) => {
  try {
    return await request.get(`/api/devices/${serialNumber}/bind/status/get`, {});
  } catch (error) {
    return false;
  }
};

// 删除站点下的设备
const deleteDeviceInsite = async (siteId: string, subSiteId: string, deviceId: string) => {
  try {
    return await request.del(
      `/api/organization/customer/sites/${siteId}/subsites/${subSiteId}/device/${deviceId}/delete`,
      {}
    );
  } catch (error) {
    return false;
  }
};

// 删除非站点下的设备
const deleteDeviceNotInsite = async (deviceId: string) => {
  try {
    return await request.put(`/api/devices/${deviceId}/unbind/put`, {});
  } catch (error) {
    return false;
  }
};

const deleteDeviceNotInSite1 = async (organizationId: string, deviceId: string) => {
  if (organizationId) {
    try {
      return await request.del(`/api/organizations/${organizationId}/customer/devices/${deviceId}/delete`, {});
    } catch (error) {
      return false;
    }
  } else {
    try {
      return await request.del(`/api/organizations/${organizationId}/customer/devices/${deviceId}/delete`, {});
    } catch (error) {
      return false;
    }
  }
};

// 编辑站点下的设备
const editDeviceInsite = async (
  siteId: string,
  subSiteId: string,
  deviceId: string,
  params: { deviceName: string }
) => {
  try {
    return await request.put(
      `/api/organization/customer/sites/${siteId}/subsites/${subSiteId}/devices/${deviceId}/put`,
      params
    );
  } catch (error) {
    return false;
  }
};

// 编辑非站点下的设备
const editDeviceNotInsite = async (deviceId: string, params: { deviceName: string }) => {
  try {
    return await request.put(`/api/devices/${deviceId}/put`, params);
  } catch (error) {
    return false;
  }
};

// 停止运维公司的服务
const stopCooperation = async (organizationId: string) => {
  try {
    return await request.del(`/api/organizations/${organizationId}/customer/delete`, {});
  } catch (error) {
    return false;
  }
};

// BO同意申请运维
const approveMaintenance = async (organizationId: string, requestId: string) => {
  try {
    return await request.post(
      `/api/organizations/${organizationId}/customer/access-requests/${requestId}/accept/post`,
      {}
    );
  } catch (error) {
    return false;
  }
};

// BO拒绝申请运维
const declineMaintenance = async (organizationId: string, requestId: string) => {
  try {
    return await request.post(
      `/api/organizations/${organizationId}/customer/access-requests/${requestId}/decline/post`,
      {}
    );
  } catch (error) {
    return false;
  }
};

// BO取消申请运维
const revokeMaintenance = async (organizationId: string, requestId: string) => {
  try {
    return await request.post(
      `/api/organizations/${organizationId}/customer/access-requests/${requestId}/revoke/post`,
      {}
    );
  } catch (error) {
    return false;
  }
};

// BO修改申请运维
const modifyMaintenance = async (organizationId: string, requestId: string, params: object) => {
  try {
    return await request.put(`/api/organizations/${organizationId}/customer/access-requests/${requestId}/put`, params);
  } catch (error) {
    return false;
  }
};

// BO站点申请运维
const miantenanceForSite = async (organizationId: string, siteId: string, params: object) => {
  try {
    return await request.post(
      `/api/organizations/${organizationId}/customer/sites/${siteId}/access-requests/post`,
      params
    );
  } catch (error: any) {
    // return false;
    if (error.response.status === 409 && error.response.data === "DEVICE_HAS_EXIST_ACCEPT_REQUEST") {
      return error.response.data;
    } else {
      return false;
    }
  }
};

// BO设备申请运维
const miantenanceForDevice = async (organizationId: string, deviceId: string, params: object) => {
  try {
    return await request.post(
      `/api/organizations/${organizationId}/customer/devices/${deviceId}/access-requests/post`,
      params
    );
  } catch (error: any) {
    // return false;
    if (error.response.status === 409 && error.response.data === "DEVICE_HAS_EXIST_ACCEPT_REQUEST") {
      return error.response.data;
    } else {
      return false;
    }
  }
};

// 获取设备license
const getDeviceLicense = async (deviceId: string) => {
  if (deviceId) {
    try {
      const infos = await request.get(`/api/device/${deviceId}/license/get`, {});
      return {
        license: {
          validFrom: infos?.license?.validFrom || "",
          validTo: infos?.license?.validTo || "",
        },
      };
    } catch (error) {
      return {
        license: {
          validFrom: "",
          validTo: "",
        },
      };
    }
  } else {
    return {
      license: {
        validFrom: "",
        validTo: "",
      },
    };
  }
};

const formatDevicesNotInSite = async (list: { [key: string]: any }[]) => {
  const tableData: { [key: string]: any }[] = [];
  try {
    for (const item of list) {
      const chooseKey = [];
      const licenseData = await getDeviceLicense(item.deviceId);
      const deviceInfos = await getDeviceInfos(item.deviceId);
      // const infos = await getMaintenanceNotInSiteInfos(organizationsId, customerId, item.deviceId, place);
      // const activeSessionDtoList = infos.activeSessionDtoList || [];
      const startTime = licenseData.license.validFrom;
      const endTime = licenseData.license.validTo;
      let time = "";
      // const haveWarningList = warinigList.filter((warningItem: { [key: string]: any }) => {
      //   const operations = warningItem?.payload?.operation || [];
      //   let deviceId = "";
      //   operations.forEach((operationItem: { [key: string]: any }) => {
      //     if (operationItem.deviceId) {
      //       deviceId = operationItem.deviceId;
      //     }
      //   });
      //   return item.deviceId === deviceId;
      // });
      if (deviceInfos.connectionState === "Connected") {
        chooseKey.push("online");
      } else if (deviceInfos.connectionState === "Disconnected") {
        chooseKey.push("offline");
      } else if (startTime === "") {
        chooseKey.push("abnormal");
      }
      if (startTime) {
        if (endTime) {
          if (new Date(endTime) > new Date()) {
            chooseKey.push("activated");
            time = `${new Date(startTime).getFullYear()}-${(new Date(startTime).getMonth() + 1 + "").padStart(
              2,
              "0"
            )} 至 ${new Date(endTime).getFullYear()}-${(new Date(endTime).getMonth() + 1 + "").padStart(2, "0")}`;
          } else {
            chooseKey.push("expired");
            time = "已过期";
          }
        }
      } else {
        chooseKey.push("unactivated");
        time = "未激活";
      }
      tableData.push({
        index: item.deviceName || "-",
        siteId: "",
        site: "",
        deviceId: item.deviceId || "",
        deviceName: item.deviceName || "",
        deviceType: deviceInfos.type || "",
        equipmentInfo: {
          name: item.deviceName || "-",
          code: deviceInfos.serialNumber || "-",
        },
        permission: {
          time: time,
        },
        warningInfo: "",
        chooseKey: chooseKey,
        status: deviceInfos.connectionState,
        miantenanceStatus: "",
        requestId: "",
        duration: ["", ""],
        isMaintenancing: false,
        maintenancingEmployees: [],
        detail: {
          period: "-",
          remark: "-",
          isPermanent: false,
          datePeriod: ["", ""],
        },
      });
    }
    return tableData;
  } catch (error) {
    return tableData;
  }
};

// 获取客户下所有的device
const getdevicesInCustomer = async (userId: string) => {
  try {
    const list = await request.get(`/api/customers/${userId}/devices/get`, {});
    // const resList = list.map((item: any) => {
    //   return {
    //     index: item.deviceName || "-",
    //     deviceName: item.deviceName || "-",
    //     serialNumber: item.serialNumber || "-",
    //     deviceOwner: item.deviceOwner || "-",
    //     description: item.description || "-",
    //     status: item.status,
    //   };
    // });
    const resList = formatDevicesNotInSite(list);
    return resList;
  } catch (error) {
    return [];
  }
};

export {
  getOrganizationInfos,
  getCustomerInfos,
  getSessions,
  deleteSessionById,
  addDevice,
  miantenanceForSite,
  miantenanceForDevice,
  availableOrganizations,
  getBoOrganization,
  createOrganizations,
  getDeviceBindStatus,
  deleteDeviceInsite,
  deleteDeviceNotInsite,
  deleteDeviceNotInSite1,
  editDeviceInsite,
  editDeviceNotInsite,
  stopCooperation,
  approveMaintenance,
  declineMaintenance,
  revokeMaintenance,
  modifyMaintenance,
  getdevicesInCustomer,
  getDeviceInfos,
};
