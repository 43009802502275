import dayjs from "dayjs";

export const phoneRule = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

//  格式化手机号
export const formatPhone = (phone: string): string => {
  const temp = [];
  temp[0] = phone.slice(0, 3);
  temp[1] = phone.slice(3, 7);
  temp[2] = phone.slice(7);
  return temp.join(" ");
};

export function formatTime(time: string): string {
  return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
}

// 节流
export function throttle(fn: any, delay = 500) {
  let timer: any = null;
  return function (this: any, ...args: any) {
    if (!timer) {
      timer = setTimeout(() => {
        fn.apply(this, args);
        timer = null;
      }, delay);
    }
  };
}

// 防抖
export function debounce(func: any, wait: any) {
  let timer: any;
  return function (this: any, ...args: any) {
    const context = this;

    clearTimeout(timer);
    timer = setTimeout(function () {
      func.apply(context, args);
    }, wait);
  };
}

// 防抖-立即执行
export function debounceFromNow(func: any, wait: any) {
  let timeout: any;
  return function (this: any, ...args: any) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    const callNow = !timeout;
    timeout = setTimeout(() => {
      timeout = null;
    }, wait);
    if (callNow) func.apply(context, args);
  };
}

export function formatDate(time: string, separator = "") {
  const dateObj = new Date(time);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1 + "";
  const day = dateObj.getDate() + "";
  const hour = dateObj.getHours() + "";
  const minute = dateObj.getMinutes() + "";
  if (separator) {
    return `${year}${separator}${month.padStart(2, "0")}${separator}${day.padStart(2, "0")} ${hour.padStart(
      2,
      "0"
    )}:${minute.padStart(2, "0")}`;
  } else {
    return `${year}年${month}月${day}日 ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
  }
}

export function formatDateData(createTime: string, endTime: string) {
  if (endTime) {
    const seconds = (new Date(endTime).getTime() - new Date(createTime).getTime()) / 1000;
    const day = Math.floor(seconds / (60 * 60 * 24));
    const hour = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
    const minute = Math.floor((seconds % (60 * 60)) / 60);
    return {
      createTime: formatDate(createTime),
      endTime: formatDate(endTime),
      duration:
        `${day ? day + "天 " : ""}${hour ? hour + "小时 " : ""}${minute ? minute + "分钟" : ""}` ||
        (seconds % 60) + "秒",
    };
  } else {
    return {
      createTime: formatDate(createTime),
      endTime: "-",
      duration: "-",
    };
  }
}

export function isArray(data: any) {
  return Object.prototype.toString.call(data) === "[object Array]";
}

//深克隆
export function cloneDeep(obj: any) {
  const objClone: any = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = cloneDeep(obj[key]);
        } else {
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}

/**
 * 用于给"/1/2/3"类型的数据排序
 * @param arr 数据列表
 * @param key 数组item中的key
 * @param separator 分割符
 */
export function sortByString(arr: any[], key: string) {
  arr.sort((a: any, b: any) => {
    const aGroups = a[key].split("/");
    const bGroups = b[key].split("/");
    const aNum = aGroups[0] * 1;
    const bNum = bGroups[0] * 1;
    if (aNum > bNum) {
      return 1;
    } else if (aNum < bNum) {
      return -1;
    } else {
      return 0;
    }
  });

  arr.sort((a: any, b: any) => {
    const aGroups = a[key].split("/");
    const bGroups = b[key].split("/");
    const aNum = aGroups[1] * 1;
    const bNum = bGroups[1] * 1;
    if (aGroups[0] === bGroups[0]) {
      if (aNum > bNum) {
        return 1;
      } else if (aNum < bNum) {
        return -1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  });

  arr.sort((a: any, b: any) => {
    const aGroups = a[key].split("/");
    const bGroups = b[key].split("/");
    const aNum = aGroups[2] * 1;
    const bNum = bGroups[2] * 1;
    if (aGroups[0] === bGroups[0] && aGroups[1] === bGroups[1]) {
      if (aNum > bNum) {
        return 1;
      } else if (aNum < bNum) {
        return -1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  });
}

/**
 * @description 比较两个版本号的大小
 * @param version1 版本1
 * @param version2 版本2
 * @returns 1 -1 0
 */
export function compareVersion(version1: string, version2: string) {
  const arr1 = version1.split(".").map((v1) => Number(v1) || 0);
  const arr2 = version2.split(".").map((v2) => Number(v2) || 0);
  const maxLen = Math.max(arr1.length, arr2.length);

  for (let i = 0; i < maxLen; i++) {
    const num1 = i < arr1.length ? arr1[i] : 0;
    const num2 = i < arr2.length ? arr2[i] : 0;

    if (num1 > num2) {
      return 1; // version1 is greater
    } else if (num1 < num2) {
      return -1; // version2 is greater
    }
  }
  return 0; // versions are equal
}
