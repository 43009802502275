import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./style/global.scss";
// import * as echarts from "echarts";
import echarts from "./echarts";
import "./icon.js";
import dayjs from "dayjs";
import axios from "axios";

Vue.use(ElementUI);

Vue.config.productionTip = false;

Vue.prototype.$echarts = echarts;
Vue.prototype.$dayJs = dayjs;
Vue.prototype.$axios = axios;

declare module "vue/types/vue" {
  interface Vue {
    $axios: any;
  }
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
