import Vue from "vue";
import Vuex from "vuex";

import userInfo from "./userInfo";
import common from "./common";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userInfo,
    common,
  },
});
