var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "layout", staticClass: "msp-layout" },
    [
      _c("div", { staticClass: "msp-header" }, [
        _c("div", { staticClass: "msp-header-content" }, [
          _c("div", { staticClass: "logo" }, [
            _c("img", {
              attrs: { src: require("@/assets/abb-logo.svg"), alt: "logo" },
              on: { click: _vm.BackToHome },
            }),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "text" }, [
              _c("span", [_vm._v("Maintenance and Service Platform")]),
              _c("div", [
                _c("span", [_vm._v("KNX数字运维管理平台")]),
                _vm.version
                  ? _c("span", { staticClass: "versionSpan" }, [
                      _vm._v(_vm._s("V" + _vm.version)),
                    ])
                  : _vm._e(),
                _vm.curmenus !== "role"
                  ? _c(
                      "span",
                      {
                        class: _vm.isTD
                          ? "tdTypeSpan typeSpan"
                          : "boTypeSpan typeSpan",
                      },
                      [_vm._v(_vm._s(_vm.isTD ? "运维端" : "业主端"))]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            _vm._l(_vm.menus, function (menu) {
              return _c(
                "div",
                {
                  key: menu.name,
                  staticClass: "menu-name",
                  class: _vm.curmenus === menu.name ? "active" : "",
                  on: {
                    click: function ($event) {
                      return _vm.toPage(menu.name)
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(menu.title) + " "),
                  (menu.name === "TDmessage" || menu.name === "BOmessage") &&
                  _vm.totalUnreadMsgCount
                    ? _c("span", { staticClass: "countTip" })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "user" },
            [
              _c("iconpark-icon", {
                attrs: { name: _vm.isTD ? "operation" : "owner", size: "34" },
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "light",
                    content: _vm.userInfo.name + " " + _vm.curOrganizationName,
                    placement: "top-start",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "user-name",
                      attrs: { tabindex: "-1" },
                      on: { click: _vm.handleShowPanel, blur: _vm.hidePanel },
                    },
                    [
                      _c("span", { staticClass: "userSpan" }, [
                        _vm._v(
                          _vm._s(
                            _vm.userInfo.aliasName || _vm.userInfo.name || "-"
                          )
                        ),
                      ]),
                      _c("span", { staticClass: "orgSpan" }, [
                        _vm._v(_vm._s(_vm.curOrganizationName || "-")),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm.isPanelShow
                ? _c("el-cascader-panel", {
                    staticClass: "user-panel",
                    attrs: {
                      props: {
                        expandTrigger: "hover",
                      },
                      options: _vm.options,
                    },
                    nativeOn: {
                      mousedown: function ($event) {
                        return _vm.handleClick.apply(null, arguments)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "panel-row",
                                  class: data.role ? "twoLine" : "singleLine",
                                  attrs: {
                                    "data-isTarget": data.isTarget,
                                    "data-parent": data.parent,
                                    "data-key": data.value,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orgLine" },
                                    [
                                      data.icon
                                        ? _c("iconpark-icon", {
                                            staticStyle: { color: "#3366ff" },
                                            attrs: {
                                              name: data.icon,
                                              size: "24",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(_vm._s(data.label) + " "),
                                    ],
                                    1
                                  ),
                                  data.role
                                    ? _c("div", { staticClass: "roleLine" }, [
                                        data.role.includes("organization_owner")
                                          ? _c("span", [_vm._v("管理员 ")])
                                          : _vm._e(),
                                        data.role.includes(
                                          "organization_employee"
                                        )
                                          ? _c("span", [_vm._v("工程师 ")])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  data.isTarget
                                    ? _c("iconpark-icon", {
                                        staticClass: "checkIcon",
                                        attrs: {
                                          name: "checkmark",
                                          size: "14",
                                          stroke: "#3366FF",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      907867438
                    ),
                  })
                : _vm._e(),
              _c("iconpark-icon", { attrs: { name: "earth", size: "24" } }),
              _c("span", { staticClass: "user-language" }, [
                _vm._v(_vm._s(_vm.curLanguage)),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm.pushItemList.length && _vm.curmenus !== "role"
        ? _c("div", { staticClass: "msgs-container" }, [
            _c(
              "div",
              { staticClass: "msgs-list" },
              _vm._l(_vm.pushItemList, function (item, index) {
                return _c("msgPushItem", {
                  key: index,
                  attrs: { itemProps: item },
                  on: { deleteItem: _vm.deleteMsgItem },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "msp-content" }, [_c("router-view")], 1),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "邀请客户",
            visible: _vm.inviteDialogVisible,
            "custom-class": "inviteDialog",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.inviteDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "formBox" },
            [
              _c(
                "el-form",
                { ref: "form", attrs: { inline: false } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请输入您客户的邮箱地址" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "邮箱地址" },
                        model: {
                          value: _vm.invitationEmail,
                          callback: function ($$v) {
                            _vm.invitationEmail = $$v
                          },
                          expression: "invitationEmail",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.sendInvitation },
                },
                [_vm._v("发送")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.companyLoading,
              expression: "companyLoading",
            },
          ],
          attrs: {
            title: "创建运维公司",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.companyHandleClose,
            "close-on-click-modal": false,
            fullscreen: false,
            "custom-class": "companyDialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.addRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名:", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话号码:", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        "例：0755-88888888 或者 13855556666 任意一种",
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "国家:", prop: "country" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择国家" },
                      on: {
                        change: function ($event) {
                          return _vm.getRegionData("country", $event)
                        },
                      },
                      model: {
                        value: _vm.form.country,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "country", $$v)
                        },
                        expression: "form.country",
                      },
                    },
                    _vm._l(_vm.countries, function (country) {
                      return _c("el-option", {
                        key: country.value,
                        attrs: { label: country.label, value: country.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "省份:", prop: "province" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择省份" },
                      on: {
                        change: function ($event) {
                          return _vm.getRegionData("province", $event)
                        },
                      },
                      model: {
                        value: _vm.form.province,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "province", $$v)
                        },
                        expression: "form.province",
                      },
                    },
                    _vm._l(_vm.provinces, function (province) {
                      return _c("el-option", {
                        key: province.value,
                        attrs: { label: province.label, value: province.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市:", prop: "city" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择城市" },
                      on: {
                        change: function ($event) {
                          return _vm.getRegionData("city", $event)
                        },
                      },
                      model: {
                        value: _vm.form.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "city", $$v)
                        },
                        expression: "form.city",
                      },
                    },
                    _vm._l(_vm.cities, function (city) {
                      return _c("el-option", {
                        key: city.value,
                        attrs: { label: city.label, value: city.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址:", prop: "address" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮编:", prop: "postcode" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.postcode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "postcode", $$v)
                      },
                      expression: "form.postcode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述:", prop: "description" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.createOrgRemarkPlaceholder,
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "applicationTip font-f-rg" }, [
            _vm._v(
              " 备注：申请需要经过ABB后台人工审核，审核通过后，您可以邀请工程师和客户进入您所创建的组织，来进行运维活动 "
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCreateOrganization },
                },
                [_vm._v("发送")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }