import http from "./axios";
import { Message } from "element-ui";

const get: (url: string, params: object, headers?: any) => any = (url, params, headers) => {
  if (headers) {
    headers["Cache-Control"] = "no-cache,no-store";
  } else {
    headers = {
      "Cache-Control": "no-cache,no-store",
    };
  }
  return new Promise((resolve, reject) => {
    http({
      method: "get",
      url,
      params,
      headers,
    })
      .then((response) => {
        if (response.status === 200) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        // if (error.status === 403) {
        //   location.replace(process.env.FRONTEND_ORIGIN);
        // }
        reject(error);
      });
  });
};

const post: (url: string, data: object, headers?: any) => any = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    http({
      method: "post",
      url,
      data,
      headers,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const put: (url: string, data: object, headers?: any) => any = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    http({
      method: "put",
      url,
      data,
      headers,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const del: (url: string, data: object, headers?: any) => any = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    http({
      method: "delete",
      url,
      data,
      headers,
    })
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const patch: (url: string, data: object, headers?: any) => any = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    http({
      method: "patch",
      url,
      data,
      headers,
    })
      .then((response) => {
        if (response.status === 204) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const request = {
  get,
  post,
  del,
  patch,
  put,
};

export default request;
