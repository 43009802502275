// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/ABBvoice_WCNSG_Md.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/ABBvoice_WCNSG_Rg.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/ABBvoice_ACNSG_Md_login.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n/* @font-face {\r\n  font-family: \"ABBvoice CNSG App Md\";\r\n  src: url(\"./assets/fonts/ABBvoice_ACNSG_Md.ttf\");\r\n} */\n@font-face {\r\n  font-family: \"ABBvoice CNSG App Md\";\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\r\n\r\n/* @font-face {\r\n  font-family: \"ABBvoice CNSG App Rg\";\r\n  src: url(\"./assets/fonts/ABBvoice_ACNSG_Rg.ttf\");\r\n} */\n@font-face {\r\n  font-family: \"ABBvoice CNSG App Rg\";\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\r\n  font-family: \"ABBvoice CNSG App Md Index\";\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.noSpan[data-v-7ba5bd90] {\r\n  font-family: \"ABBvoice CNSG App Md\";\n}\r\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
