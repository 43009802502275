import request from "@/axios/http";
const organizationId = "5e29aced-cf49-11ec-be9b-0242ac110003";
const uuid = "5edb7052-cf49-11ec-be9b-0242ac110003";
import { formatDateData } from "@/utils/index";

// 获取客户列表
const getCustomers = async (organizationId: string, employeeId: string) => {
  try {
    const customers = await request.get(
      `/api/organizations/${organizationId}/employees/${employeeId}/customers/get`,
      {}
    );
    const customer_userIds: string[] = [];
    const customerIds: string[] = [];
    const moreData: { [key: string]: any }[] = [];
    customers.forEach((item: { [key: string]: string }) => {
      customer_userIds.push(item.userId);
      customerIds.push(item.uuid);
      moreData.push({
        customerId: item.uuid,
        userId: item.userId,
        customerName: item.name,
        aliasName: item.aliasName || "",
        email: item.email,
      });
    });
    return {
      customerIds,
      customer_userIds,
      moreData,
    };
  } catch (error) {
    return {
      customerIds: [],
      customer_userIds: [],
      moreData: [],
    };
  }
};

// 获取重要客户列表
const getVipCustomers = async (organizationId: string) => {
  try {
    const VIPCustomers = await request.get(`/api/organizations/${organizationId}/employee/vip-customers/get`, {});
    const list: { [key: string]: string }[] = [];
    VIPCustomers.forEach((item: { [key: string]: any }) => {
      list.push({
        name: item.name,
        aliasName: item.aliasName || "",
        email: item.customerEmail,
        userId: item.customerUserId,
        customerId: item.customerId,
        vipId: item.uuid,
      });
    });
    return list;
  } catch (error) {
    return [];
  }
};

// 获取非重要客户列表
const getNotVipCustomers = async (organizationId: string) => {
  try {
    const NotVIPCustomers = await request.get(`/api/organizations/${organizationId}/employee/nonvip-customers/get`, {});
    const list: { [key: string]: string }[] = [];
    NotVIPCustomers.forEach((item: { [key: string]: any }) => {
      list.push({
        name: item.name,
        aliasName: item.aliasName || "",
        email: item.customerEmail,
        userId: item.customerUserId,
        vipId: item.uuid,
      });
    });
    return list;
  } catch (error) {
    return [];
  }
};

// 编辑重要客户列表
const editVipCustomers = async (organizationId: string, vipIds: string[]) => {
  try {
    return await request.post(`/api/organizations/${organizationId}/employee/vip-customers/post`, {
      vipIds: vipIds,
    });
  } catch (error) {
    return false;
  }
};

// 获取客户下所有的device
const getdevicesInCustomer = async (userId: string) => {
  try {
    const list = await request.get(`/api/customers/${userId}/devices/get`, {});
    let onlineCount = 0;
    let offlineCount = 0;
    let abnormalCount = 0;
    const deviceIds: { [key: string]: string }[] = [];
    const deviceIdList = list.map((item: { [key: string]: any }) => {
      return item.deviceId;
    });
    const warningList = await getDevicesWarningInfos(deviceIdList);
    for (const item of list) {
      const deviceWarning: any = warningList.find((deviceItem: { deviceId: string; count: number }) => {
        return deviceItem.deviceId === item.deviceId;
      });
      const warningMsgCount = deviceWarning?.count || 0;
      const licenseItem = await getDeviceLicense(item.deviceId);
      if (item.connectionState === "Connected" && licenseItem.license.validFrom && warningMsgCount === 0) {
        onlineCount++;
      } else if (item.connectionState === "Disconnected") {
        offlineCount++;
      } else {
        abnormalCount++;
      }
      deviceIds.push({ deviceId: item.deviceId });
    }
    return {
      total: list.length,
      onlineCount,
      offlineCount,
      abnormalCount,
      deviceIds,
    };
  } catch (error) {
    return {
      total: 0,
      onlineCount: 0,
      offlineCount: 0,
      abnormalCount: 0,
      deviceIds: [],
    };
  }
};

// 获取多个设备的license
const getlicenseFromDevices = async (deviceIds: { [key: string]: string }[]) => {
  if (deviceIds.length) {
    const data = await request.post(`/api/license/query/post`, deviceIds);
    return data;
  } else {
    return [];
  }
};

// 获取设备license
const getDeviceLicense = async (deviceId: string) => {
  if (deviceId) {
    try {
      const infos = await request.get(`/api/device/${deviceId}/license/get`, {});
      return {
        license: {
          validFrom: infos?.license?.validFrom || "",
          validTo: infos?.license?.validTo || "",
        },
      };
    } catch (error) {
      return {
        license: {
          validFrom: "",
          validTo: "",
        },
      };
    }
  } else {
    return {
      license: {
        validFrom: "",
        validTo: "",
      },
    };
  }
};

// 获取客户下的设备id(非站点下的)和站点Id
const getAllDevices = async (organizationId: string, customerId: string) => {
  try {
    const data = await request.get(`/api/organizations/${organizationId}/customers/${customerId}/devices/get`, {});
    const siteIds: string[] = [];
    const deviceIds: string[] = [];
    data.forEach((item: { [key: string]: string }) => {
      if (item.siteId) {
        siteIds.push(item.siteId);
      } else if (item.deviceId) {
        deviceIds.push(item.deviceId);
      }
    });
    return {
      siteIds: Array.from(new Set(siteIds)),
      deviceIds: Array.from(new Set(deviceIds)),
    };
  } catch (error) {
    return {
      siteIds: [],
      deviceIds: [],
    };
  }
};

// 设备站点/设备运维信息
const getAccessRequest = async (organizationId: string, customerId: string) => {
  const statuslist: string[] = [];
  try {
    const data = await getAllDevices(organizationId, customerId);
    if (data.siteIds.length) {
      const infoList = await request.post(
        `/api/organizations/${organizationId}/customers/${customerId}/sites/access-requests/query/post`,
        data.siteIds
      );
      infoList.forEach((item: { [key: string]: any }) => {
        statuslist.push(item.status);
      });
    }
    if (data.deviceIds) {
      const infoList = await request.post(
        `/api/organizations/${organizationId}/customers/${customerId}/devices/access-requests/query/post`,
        data.deviceIds
      );
      infoList.forEach((item: { [key: string]: any }) => {
        statuslist.push(item.status);
      });
    }
    return statuslist;
  } catch (error) {
    return statuslist;
  }
};

// 格式化重要客户列表
const getVipslist = async (organizationId: string) => {
  const vips = await getVipCustomers(organizationId);
  const vipsList: { [key: string]: any } = [];
  for (const item of vips) {
    const deviceInfos = await getdevicesInCustomer(item.userId);
    // const licenses = await getlicenseFromDevices(deviceInfos.deviceIds);
    // const promiseList: any[] = [];
    // deviceInfos.deviceIds.forEach((deviceItem: { [key: string]: string }) => {
    //   promiseList.push(getDeviceLicense(deviceItem.deviceId));
    // });
    const linceseList = [];
    for (const item of deviceInfos.deviceIds) {
      const result = await getDeviceLicense(item.deviceId);
      linceseList.push(result);
    }
    const licenses = linceseList.filter((item) => {
      return item.license.validFrom;
    });
    const unactiveCount = deviceInfos.deviceIds.length - licenses.length;
    const statusList = await getAccessRequest(organizationId, item.customerId);
    const tipsList = [];
    if (deviceInfos.offlineCount) {
      tipsList.push({ type: "offline", text: "请检查离线设备" });
    }
    if (unactiveCount) {
      tipsList.push({ type: "activeStatus", text: `${unactiveCount}个设备未激活` });
    }
    if (statusList.includes("APPROVED")) {
      tipsList.push({ type: "maintenanceStatus", text: `有运维任务` });
    }
    // if (tipsList.length === 0) {
    //   tipsList.push({ type: "normal", text: "一切正常" });
    // }
    vipsList.push({
      vipId: item.vipId,
      name: item.name,
      aliasName: item.aliasName,
      customerId: item.customerId,
      totleCount: deviceInfos.total,
      email: item.email,
      state: {
        abnormal: deviceInfos.abnormalCount,
        offline: deviceInfos.offlineCount,
        normal: deviceInfos.onlineCount,
      },
      btns: tipsList,
    });
  }
  return vipsList;
};

// 获取运维任务数量
const getMaintenanceTaskCount = async (organizationId: string, employeeId: string) => {
  try {
    const data = await request.get(
      `/api/organizations/${organizationId}/employee/${employeeId}/access-requests/total-task/get`,
      {}
    );
    return data.totalMaintainTask;
  } catch (error) {
    return 0;
  }
};

const getMessages = async (params: { [key: string]: any }) => {
  try {
    const data = await request.get(`/api/message/list/get`, params);
    const list = data.list.filter((item: { [key: string]: any }) => {
      return item.status === "unread";
    });
    return list;
  } catch (error) {
    return [];
  }
};

// 获取运维总时长
const getMaintenanceDuration = async (organizationId: string, employeeId: string) => {
  if (organizationId && employeeId) {
    try {
      const data = await request.get(
        `/api/organizations/${organizationId}/employees/${employeeId}/sessions/total-durations/get`,
        {}
      );
      return data.totalDuration;
    } catch (error) {
      return 0;
    }
  } else {
    return 0;
  }
};

// 根据deviceId获取设备详情
const getDeviceInfos = async (deviceId: string) => {
  if (deviceId) {
    try {
      const infos = await request.get(`/api/devices/${deviceId}/get`, {});
      return {
        id: infos.deviceId,
        name: infos.deviceName,
        status: infos.connectionState,
      };
    } catch (error) {
      return {
        id: "",
        name: "",
        status: "",
      };
    }
  } else {
    return {
      id: "",
      name: "",
      status: "",
    };
  }
};

// 批量获取多个客户下的设备以及状态
const getDevicesFromCustomers = async (userIds: string[]) => {
  if (userIds.length) {
    try {
      const data = await request.post(`/api/customers/devices/query/post`, { userIds });
      const list = data.filter((item: { [key: string]: string }) => {
        return item.deviceId !== "";
      });
      const deviceIds = list.map((item: { [key: string]: any }) => {
        return item.deviceId;
      });
      const allDevicesWarningInfo = await getDevicesWarningInfos(deviceIds);
      const customerIds: any[] = [];
      for (const item of list) {
        const curDeviceInfo = allDevicesWarningInfo.find((infoItem: { [key: string]: any }) => {
          return item.deviceId === infoItem.deviceId;
        });
        const licenseItem = await getDeviceLicense(item.deviceId);
        customerIds.push({
          deviceId: item.deviceId,
          deviceName: item.deviceName,
          status: item.connectionState,
          type: item.type,
          serialNumber: item.serialNumber,
          macAddress: item.macAddress,
          license: licenseItem.license,
          warningMsgCount: curDeviceInfo ? curDeviceInfo.count : 0,
          deviceOwner: item.deviceOwner,
        });
      }
      return customerIds;
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
};

// 获取站点设备
const getDevicesInSite = async (organizationId: string, employeeId: string) => {
  try {
    const data = await request.get(
      `/api/organizations/${organizationId}/employee/${employeeId}/customers/sites/devices/get`,
      {}
    );
    const list = data.filter((item: { [key: string]: string }) => {
      return item.deviceId;
    });
    const tempSiteIdList = data.map((item: { [key: string]: string }) => {
      return item.siteId;
    });
    const siteSet = new Set(tempSiteIdList);
    const siteIdList = Array.from(siteSet);
    return { list: list, siteIds: siteIdList };
  } catch (error) {
    return { list: [], siteIds: [] };
  }
};

// 移除重点客户
const removeVipCustomer = async (organizationId: string, vipId: string) => {
  try {
    const data = await request.del(`/api/organizations/${organizationId}/employee/vip-customer/${vipId}/delete`, {});
    return data;
  } catch (error) {
    return false;
  }
};

const getSitesDevices = async (organizationId: string, employeeId: string) => {
  const { list: list, siteIds: allSiteIds } = await getDevicesInSite(organizationId, employeeId);
  const deviceIdList = list.map((item: { [key: string]: any }) => {
    return item.deviceId;
  });
  const warningList = await getDevicesWarningInfos(deviceIdList);
  const customerData = await getCustomers(organizationId, employeeId);
  const customerIds = customerData.customer_userIds;
  let siteData = {
    normalCount: 0,
    offlineCount: 0,
    abnormalCount: 0,
  };
  let deviceData = {
    total: 0,
    normalCount: 0,
    offlineCount: 0,
    abnormalCount: 0,
  };
  if (list.length) {
    let normalCount = 0;
    let offlineCount = 0;
    // let abnormalCount = 0;
    const sitesMap = new Map();
    for (const item of list) {
      const deviceWarning: any = warningList.find((deviceItem: { deviceId: string; count: number }) => {
        return deviceItem.deviceId === item.deviceId;
      });
      const infos = await getDeviceInfos(item.deviceId);
      const licenseItem = await getDeviceLicense(item.deviceId);
      const deviceStatusList = sitesMap.get(item.siteId) || [];
      deviceStatusList.push({
        status: infos.status,
        license: licenseItem.license,
        warningMsgCount: deviceWarning?.count || 0,
      });
      sitesMap.set(item.siteId, deviceStatusList);
    }
    sitesMap.forEach((devices, site) => {
      const isAllConnneted = devices.every((item: any) => {
        return item.status === "Connected" && item.license.validFrom && item.warningMsgCount === 0;
      });
      if (isAllConnneted) {
        normalCount++;
      } else {
        const isDisConnected = devices.every((item: any) => {
          return (
            item.status === "Disconnected" ||
            (item.status === "Connected" && item.license.validFrom && item.warningMsgCount === 0)
          );
        });
        if (isDisConnected) {
          offlineCount++;
        }
      }
    });
    siteData = {
      normalCount,
      offlineCount,
      abnormalCount: allSiteIds.length - normalCount - offlineCount,
    };
  } else {
    siteData = {
      normalCount: 0,
      offlineCount: 0,
      abnormalCount: allSiteIds.length,
    };
  }
  if (customerIds.length) {
    const deviceList = await getDevicesFromCustomers(customerIds);
    let deviceNormalCount = 0;
    let deviceAbnormalCount = 0;
    let deviceofflineCount = 0;
    deviceList.forEach((device) => {
      if (device.status === "Connected" && device.license.validFrom && device.warningMsgCount === 0) {
        deviceNormalCount++;
      } else if (device.status === "Disconnected") {
        deviceofflineCount++;
      } else {
        deviceAbnormalCount++;
      }
    });
    deviceData = {
      total: deviceList.length,
      normalCount: deviceNormalCount,
      offlineCount: deviceofflineCount,
      abnormalCount: deviceAbnormalCount,
    };
  }
  return {
    site: siteData,
    device: deviceData,
  };
};

// 获取用户角色
const getUserRole = async () => {
  try {
    return await request.get(`/api/roles/get`, {});
  } catch (error) {
    return [];
  }
};

const getDevicesWarningInfos = async (deviceIdList: string[]) => {
  try {
    let result = [];
    if (deviceIdList.length) {
      result = await request.post(`/api/alert/count/post`, {
        deviceIdList,
      });
    }
    return result;
  } catch (error) {
    return [];
  }
};

const getUnreadMsgCount = async (organizationId: any) => {
  try {
    const params: { [key: string]: any } = {};
    if (organizationId) {
      params.organizationId = organizationId;
    }
    const unreadedCount = await request.get(`/api/message/unread/count/get`, params);
    return Number(unreadedCount);
  } catch (error) {
    return 0;
  }
};

// 获取设备运维数据（以客户为维度）
const customersAccessRequestData = async (organizationId: string, employeeId: string) => {
  try {
    const res = await request.get(
      `/api/organizations/${organizationId}/employee/${employeeId}/customers/devices/get`,
      {}
    );
    return res || [];
  } catch (error) {
    return [];
  }
};

// 获取运维列表
const getMaintenanceList = async (organizationId: string, employeeId: string) => {
  const customerData = (await getCustomers(organizationId, employeeId)) || [];
  const deviceList = await getDevicesFromCustomers(customerData.customer_userIds);
  let accessList: { [key: string]: any }[] = [];
  const accessData = await customersAccessRequestData(organizationId, employeeId);
  if (deviceList.length) {
    for (const customer of customerData.moreData) {
      const currentCustomerAccess =
        accessData.find((cItem: any) => {
          return cItem.customerId === customer.customerId;
        }) || {};
      if (currentCustomerAccess.deviceAccessRequestList) {
        accessList = [...accessList, ...currentCustomerAccess.deviceAccessRequestList];
      }
      if (currentCustomerAccess.customerSiteDeviceList) {
        const siteDevices = currentCustomerAccess.customerSiteDeviceList;
        const siteAccessRequests = currentCustomerAccess.siteAccessRequestList || [];
        for (const deviceItem of siteDevices) {
          const siteId = deviceItem.siteId;
          let deviceARData = {};
          const parentSiteAR = siteAccessRequests.find((item: any) => {
            return item.siteId === siteId;
          });
          if (parentSiteAR) {
            deviceARData = {
              deviceId: deviceItem.deviceId,
              ...parentSiteAR,
            };
            accessList.push(deviceARData);
          }
        }
      }
    }
  }
  return deviceList.map((deviceItem: any) => {
    const currentCustomer = customerData.moreData.find((customerItem: any) => {
      return customerItem.userId === deviceItem.deviceOwner;
    });
    const selfAccess =
      accessList.find((accessItem: any) => {
        return accessItem.deviceId === deviceItem.deviceId;
      }) || {};
    const dateData = formatDateData(selfAccess.startTime, selfAccess.endTime);
    let status = "";
    if (deviceItem.status === "Connected" && deviceItem.license.validFrom && deviceItem.warningMsgCount === 0) {
      status = "online";
    } else if (deviceItem.status === "Disconnected") {
      status = "offline";
    } else {
      status = "abnormal";
    }
    return {
      deviceId: deviceItem.deviceId,
      deviceName: deviceItem.deviceName,
      model: deviceItem.type,
      connectionState: deviceItem.status,
      serialNumber: deviceItem.serialNumber,
      customer: currentCustomer || {},
      status,
      miantenanceStatus: selfAccess.status || "",
      mac: deviceItem.macAddress,
      activeSessionDtoList: selfAccess.activeSessionDtoList || [],
      maintenancingEmployee: selfAccess.activeSessionDtoList ? selfAccess.activeSessionDtoList[0].employee : {},
      accessData: {
        haveAccess: Object.keys(selfAccess).length ? true : false,
        isPermanent: selfAccess.permanent || false,
        startTime: selfAccess.startTime ? new Date(selfAccess.startTime) : "",
        endTime: selfAccess.endTime ? new Date(selfAccess.endTime) : "",
        note: selfAccess.note || "",
        duration: dateData.createTime + "-" + dateData.endTime,
        period: [selfAccess?.startTime || "", selfAccess?.endTime || ""],
        sessionId: selfAccess.activeSessionDtoList ? selfAccess.activeSessionDtoList[0].uuid : "",
      },
    };
  });
};

export {
  getVipslist,
  getNotVipCustomers,
  editVipCustomers,
  getMaintenanceTaskCount,
  getMaintenanceDuration,
  getSitesDevices,
  removeVipCustomer,
  getMessages,
  getUserRole,
  getDevicesWarningInfos,
  getUnreadMsgCount,
  getMaintenanceList,
};
