import request from "@/axios/http";
import axios from "axios";
//  获取登陆状态和用户信息
export const getLoginStatus = () => {
  const timer = new Date().getTime();
  return request.get(`/api/loginStatus?time=${timer}`, {});
};

//  登出
export const loginout = () => {
  return request.get("/api/loginout", {});
};

/**
 * Oauth2登陆
 */
export const login = () => {
  return request.get("/api/login", {});
};

// 验证码登录
export const codeLogin = async (email: string, code: string) => {
  try {
    return await request.get("/api/code-login", { email, code });
  } catch (error) {
    console.log("errorUrl: /api/code-login,", error);
    return false;
  }
};

// 获取当前环境域名
export const getDomainName = () => {
  return request.get("/api/domain/get", {});
};

export const getData = () => {
  return new Promise((resolve, reject) => {
    const axiosRequest = axios.create({
      baseURL: "https://mspstagestorage.blob.core.chinacloudapi.cn/",
      timeout: 20000, // 请求超时时间
      withCredentials: true,
    });
    axiosRequest({
      method: "get",
      url: "/pcclient-ota/update.xml",
    })
      .then((res) => {
        console.log("成功返回 =======", res);
        resolve(res);
      })
      .catch((err) => {
        console.log("错误问题是------------------>>", err);
        reject(err);
      });
  });
};

// 获取版本号
export const getCurrentVersion = async () => {
  try {
    const res = await request.get("/api/configuration/get", {});
    return res.VersionNumber || "";
  } catch (error) {
    return "";
  }
};
